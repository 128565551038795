import { Row, Col, Container, Card, Button, Table, Dropdown, Spinner, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import { useNavigate } from "react-router-dom";
import { getAllOrders, getOrderAnalytics, loginCustomer, manageOrder, setupInventory } from "../../redux/slices/inventory.slice";
import dateFormatter from "../../utils/dateFormatter";
import RecordFilter from "../../components/RecordFilter";
import InventoryConnectionButton from "../../components/InventoryConnector";

const Orders = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoaded = useRef(false)

    const initialState = {
        loading: false,
        show_vendor_form: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            amount: 1,
            page: 1,
            from_date: '',
            to_date: '',
            status: '',
            filter_by: '',
            sort_by: '',
            filter: '',
        },
        errors: {},
        suppliers: [],
        products: [],
        restockMode: false,
        product: {},
        pricelist: [],
    }

    const [state, setState] = useState(initialState)
    const [loading, setLoading] = useState(false)
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [orders, setOrders] = useState([])
    const [order, setOrder] = useState({})
    const [showOrder, setShowOrder] = useState(false)

    const { invLogin } = useSelector((state) => state.inventory)
    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)


    useEffect(() => {
        if(!isLoaded.current) {
            loadAnalytics()
            loadOrders()
            isLoaded.current = true
        }
    }, [])

    useEffect(() => {
        if(invLogin === true) {
            loadAnalytics()
            loadOrders()
        }
    }, [invLogin])

    const orderManagement = (item, option) => {
        const selectedOption = option === 'cancelled' ? 'Cancel' : (option === 'closed' ? 'Close' : option) 
        Swal.fire({
            icon: "warning",
            text: `Are you sure you want to ${selectedOption} this order?`,
            showCancelButton: true,
            showConfirmButton: true,
        })
        .then((result) => {
            if(result.isConfirmed) {
                setLoading(true)
                dispatch(manageOrder({ order_id: item.id, status: option}))
                .then((response) => {
                    if(response.payload?.status === true) {
                        loadOrders()
                        Swal.fire({
                            icon: 'success',
                            text: response.payload?.message
                        })
                    }
                    else if(response.payload?.status === false) {
                        Swal.fire({
                            icon: 'error',
                            text: response.payload?.message
                        })
                    }
                })
                .finally(() => setLoading(false))
            }
        })
    }

    const handleInput = (event) => {
        const {name, value} = event.target
         if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: newValue}}))
        }
        else {
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
        }
    }
    
    const handlePagination = (data) => {
        setState((prevState) => ({...prevState, payload: {...prevState.payload,  page: data}}))
    }

    const loadAnalytics = () => {
        dispatch(getOrderAnalytics())
        .then((response) => {
            if(response.payload?.data) {
                setState((prevState) => ({...prevState,  analytics: response.payload.data}))
            }
        })
    }

    const loadOrders = () => {
        console.log("ODA", state.payload)
        setLoading(true)
        dispatch(getAllOrders(state.payload))
        .then((response) => {
            const result = response.payload?.data?.data
            setOrders(result) 
        })
        .finally(() => setLoading(false))
    }

    const handleSearch = () => {
        loadOrders()
    }

    const handleShowOrder = (item) => {
        setOrder(item)
        setShowOrder(true)
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <InventoryConnectionButton />
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd">
                    <div className="col-md-4">
                        <h3 className="m-0">Orders</h3>
                        <p>
                            <small>Manage storefront orders and view overal analytics</small>
                        </p>                        
                    </div>
                    <div className="col-md-8 text-right">
                        <Button 
                            type="button" 
                            className="border-0 mb-2 px-3 ms-2"
                            onClick={ () => navigate(user?.user_type === 'owner' ? '/inventory/storefront/products' : '/members/inventory/storefront/products')}
                        >
                            <FontAwesomeIcon icon={'box-open'}/>
                            <span className="ps-2">Products</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="primary" 
                            className="border-0 mb-2 ms-2"
                            onClick={ () => navigate( user?.user_type === 'owner' ? '/inventory/storefront' : '/members/inventory/storefront')}
                        >
                            <FontAwesomeIcon icon={'shop'} />
                            <span className="ps-2">Storefront</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="secondary" 
                            className="border-0 mb-2 ms-2 text-white"
                            onClick={ () => navigate(user?.user_type === 'owner' ? '/inventory/sell' : '/members/inventory/sell')}
                        >
                            <FontAwesomeIcon icon={'line-chart'} />
                            <span className="ps-2">Sell Product</span>
                        </Button>
                    </div>
                </Col>

                <div className="mt-4">
                    <Row className="data-card mb-4">
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Overall Total Orders</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{formatCurrency(state.analytics?.daily_orders).formatted}</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        Overall total
                                    </span>
                                </Card.Body>
                            </Card> 
                        </Col>
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total Orders Today</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{ formatCurrency(state.analytics?.daily_order_amount).with_currency }</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                       Today
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total TAX Today</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">{ formatCurrency(state.analytics?.daily_tax).with_currency }</h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        VAT Today
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-4 align-items-center justify-content-between">
                        <div className="col-md-5">
                            <h5 className="mb-2">Search Order listing</h5>
                        </div>
                        <div className="">
                            <RecordFilter
                                handleInput={handleInput}
                                handleSearchSubmit={handleSearch}
                                params={[
                                    {label: 'Status', value: 'order_status'},
                                    {label: 'Order Number', value: 'order_number'},
                                    {label: 'Payment Method', value: 'payment_method'},
                                    {label: 'Payment Status', value: 'payment_status'},
                                    {label: 'Date', value: 'date'},
                                ]}
                            />
                        </div>
                    </Row>

                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>Order Number</th>
                                            <th>Order Date</th>
                                            <th>Total Amount</th>
                                            <th>VAT</th>
                                            <th>Payment Method</th>
                                            <th>Order Status</th>
                                            <th>Payment Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody className="data-container">
                                        {
                                            loading ? (
                                                <div className="in-page-loader">
                                                    <Spinner size="lg" variant="primary"/>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            orders?.length ? (
                                                orders?.map((item, index) => (
                                                    <tr key={item.id}> 
                                                        <td>
                                                            {index + 1}.
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-plain text-primary font-bold"
                                                                onClick={() => handleShowOrder(item)}
                                                            >
                                                                #{item.order_number}
                                                            </button> 
                                                        </td>
                                                        <td> {dateFormatter(item.created_at)}  </td>
                                                        <td>{formatCurrency(item.total).with_currency} </td>
                                                        <td>{formatCurrency(item.vat).with_currency} </td>
                                                        <td>{item.payment_method} </td>
                                                        <td>
                                                            <span className={`font-bold ${item.order_status === "open" ? "text-success" : "text-danger"}`}>
                                                                {item.order_status}
                                                            </span> 
                                                        </td>
                                                        <td>{item.payment_status} </td>
                                                        <td>
                                                            <div className="d-flex m-0 align-items-center justify-content-between">
                                                                <Dropdown>
                                                                    <Dropdown.Toggle variant="light" className="border-0 bg-transparent" size="sm" id="dropdown-basic">
                                                                        <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                    </Dropdown.Toggle>
        
                                                                    <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                        <Dropdown.Item 
                                                                            eventKey={1} 
                                                                            className="text-primary d-flex align-items-center gap-1" 
                                                                            onClick={() => handleShowOrder(item)}
                                                                        >
                                                                            <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Items
                                                                        </Dropdown.Item>
                                                                        {
                                                                            item.order_status === 'closed' || item.order_status === 'PENDING' || item.order_status === 'pending' || item.order_status === 'cancelled' ? (
                                                                                <>
                                                                                    <Dropdown.Divider />
                                                                                    <Dropdown.Item 
                                                                                        eventKey={2} 
                                                                                        className="text-success d-flex align-items-center gap-1"
                                                                                        onClick={(e) => orderManagement(item, 'open')}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={'check'} /> Open
                                                                                    </Dropdown.Item>
                                                                                </>
                                                                            ) : null
                                                                        }
                                                                        {
                                                                            item.order_status === 'open' || item.order_status === 'PENDING' ? (
                                                                                <>
                                                                                    <Dropdown.Divider />
                                                                                    <Dropdown.Item 
                                                                                        eventKey={2} 
                                                                                        className="text-danger d-flex align-items-center gap-1"
                                                                                        onClick={(e) => orderManagement(item, 'cancelled')}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={'cancel'} /> Cancel Order
                                                                                    </Dropdown.Item>
                                                                                    <Dropdown.Divider />
                                                                                    <Dropdown.Item 
                                                                                        eventKey={2} 
                                                                                        className="text-danger d-flex align-items-center gap-1"
                                                                                        onClick={(e) => orderManagement(item, 'closed')}
                                                                                    >
                                                                                        <FontAwesomeIcon icon={'times'} /> Close
                                                                                    </Dropdown.Item>
                                                                                </>
                                                                            ) : null

                                                                        }
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        orders?.length ? (
                            <div className="table-responsive mt-2">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </div>
                <Modal 
                    onHide={() => setShowOrder(false)}
                    show={showOrder}
                    backdrop="static"
                    size="lg"
                >
                    <Modal.Header closeButton className="bg-primary text-white p-1">
                        <Modal.Title as={"h5"}>#{order?.order_number}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Price(₦)</th>
                                    <th>Quantity</th>
                                    <th>Amount(₦)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    order?.item && (
                                        order?.item.map((orderItem, index) => (
                                            <tr key={orderItem.id}>
                                                <td>{index+1}. {orderItem.product_name}</td>
                                                <td>{formatCurrency(orderItem.sales_price).formatted}</td>
                                                <td>{formatCurrency(orderItem.quantity).formatted}</td>
                                                <td>{formatCurrency(orderItem.quantity * orderItem.sales_price).formatted}</td>
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer className="p-1">
                        <Button type="button" variant="primary" onClick={() => setShowOrder(false)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </Layout>
    );
}

export default Orders;