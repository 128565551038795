import { useState } from "react";
import { Col, Container, Form, Row, Button, Spinner } from "react-bootstrap";
import Logo from "../../../components/Logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import lock_image from "../../../assets/images/landing_page/lock.svg";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../../redux/slices/manager.auth.slice";
import Swal from "sweetalert2";

const ForgotPasswordMember = () => {

    const [is_active, setIsActive] = useState(false)
    const [payload, setPayload] = useState({email: ''})
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const handleInput = (event) => {
        const { name, value } = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(payload.email && payload.email.match(pattern)) 
            setIsActive(true)
        else 
            setIsActive(false)
    }

    const handleForgotPassword = () => {
        setLoading(true)
        dispatch(forgotPassword(payload))
        .then((response) => {
            // console.log(response)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                navigate("/members/reset-password", { replace: true })
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const login = () => {
        navigate('/members/login')
    }
    
    return (
        <Row className="full-height justify-content-center">
            <Col md={5}>
                <Container className="mt-3 text-center px-5">
                    <div className="mt-3 mb-5">
                        <Logo variant="dark"/>
                    </div>
                </Container>
                <Container className="mt-5 text-center pt-5">
                    <div >
                        <img src={lock_image} alt="forgot password" width={70} />
                    </div>
                    <h3 className="text-primary mt-2">Forgot Password</h3>
                    <p className="text-dark">
                        You don't need to worry, We will send a reset instructions
                    </p>
                </Container>
                <Container className="px-5 mt-5">
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} controlId="validationCustom01" className="mb-3">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    size="lg"
                                    onChange={handleInput}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md={12} controlId="validationCustom01" className="mb-3">
                                <Form.Label>Merchant ID</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Merchant ID"
                                    name="merchant"
                                    size="lg"
                                    onChange={handleInput}
                                />
                            </Form.Group>
                        </Row>
                        <div className="d-grid gap-3">
                            <Button 
                                type="button" 
                                size="lg" 
                                disabled={!is_active ? true : false}
                                onClick={handleForgotPassword}
                            >
                                Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                            </Button>
                            <Form.Group className="mb-3 text-center">
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    className="bg-white border-0 text-primary"
                                    onClick={login}
                                >
                                    <FontAwesomeIcon icon="fas fa-arrow-left" />
                                    <span className="px-1">Back to login page</span>
                                </Button>
                            </Form.Group>
                        </div>
                    </Form>
                </Container>
            </Col>
        </Row>
    );
}


export default ForgotPasswordMember;