import React from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";
import formatCurrency from "../../utils/currencyFormmter";
import axios from "axios";

const cardElementOptions = {
  style: {
    base: {
      fontSize: "18px",
      fontWeight: "500",
      color: "#ffffff",
      fontFamily: "'Poppins', sans-serif",
      "::placeholder": {
        color: "rgba(255, 255, 255, 0.7)",
      },
    },
    invalid: {
      color: "#ffb3b3",
    },
  },
};

export default function StyledCardForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.error("Stripe has not loaded yet.");
      return;
    }

    const card = elements.getElement(CardNumberElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: card,
    });

    if (error) {
      console.error("Payment error:", error);
    } else {
      console.log("PaymentMethod created:", paymentMethod);
    }
  };

  const createPaymentIntent = () => {
    const data = {
         amount: props.amount,
         currency: props.currency,
         payment_method_types: ['card'],
         payment_method: 'card',
         metadata: {
             merchant_id: props.merchant_id,
             amount: props.amount,
             currency: props.currency,
             description: `QR Payment of ${formatCurrency(props.amount, props.currency).with_currency} to ${props.store_name}`
         }
     }

     axios.post(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/api-v1/create_payment_intent/`, data)
     .then((response) => {
         // console.log(response)
     })
     .catch((error) => {
         // console.log(error)
     })
 };



  return (
    <div>
      <div style={styles.cardLogos}>
        <img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt="Visa" style={styles.cardLogo} />
        <img src="https://upload.wikimedia.org/wikipedia/commons/b/b7/MasterCard_Logo.svg" alt="MasterCard" style={styles.cardLogo} />
        <img src="https://upload.wikimedia.org/wikipedia/commons/3/30/American_Express_logo.svg" alt="Amex" style={styles.cardLogo} />
        <img src="https://upload.wikimedia.org/wikipedia/commons/f/fd/Maestro_logo.svg" alt="Maestro" style={styles.cardLogo} />
      </div>
      <div style={styles.container}>
        <div style={styles.card}>
          {/* <h3 style={styles.title}>Enter Payment Details</h3> */}
          <form onSubmit={handleSubmit}>
            <div style={styles.inputContainer}>
              <label style={styles.label}>Card Number</label>
              <div style={styles.inputField}>
                <CardNumberElement options={cardElementOptions} />
              </div>
            </div>

            <div style={styles.row}>
              <div style={{ ...styles.inputContainer, width: "48%" }}>
                <label style={styles.label}>Expiry Date</label>
                <div style={styles.inputField}>
                  <CardExpiryElement options={cardElementOptions} />
                </div>
              </div>

              <div style={{ ...styles.inputContainer, width: "48%" }}>
                <label style={styles.label}>CVC</label>
                <div style={styles.inputField}>
                  <CardCvcElement options={cardElementOptions} />
                </div>
              </div>
            </div>

            <button type="submit" style={styles.button} disabled={!stripe}>
              Pay { formatCurrency(props.amount, props.currency).with_currency }
            </button>
          </form>

        </div>
      </div>
      <div style={styles.poweredByStripe}>
        <span>Powered by:</span>
        <img src="https://upload.wikimedia.org/wikipedia/commons/b/ba/Stripe_Logo%2C_revised_2016.svg" alt="Stripe" style={styles.stripeLogo} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
  },
  card: {
    width: "500px",
    background: "linear-gradient(135deg, #675dff 0%, #594df9 100%)",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
    color: "#fff",
    fontFamily: "'Poppins', sans-serif",
    position: "relative",
  },
  title: {
    textAlign: "center",
    marginBottom: "15px",
    fontSize: "20px",
  },
  cardLogos: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "10px",
    width: "100%",
  },
  cardLogo: {
    width: "50px",
    height: "30px",
    objectFit: "contain",
  },
  inputContainer: {
    marginBottom: "15px",
  },
  label: {
    display: "block",
    fontSize: "14px",
    fontWeight: "600",
    marginBottom: "5px",
    color: "#ffffff",
  },
  inputField: {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    padding: "12px",
    borderRadius: "5px",
    border: "1px solid rgba(255, 255, 255, 0.3)",
    transition: "border 0.3s ease-in-out",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "100%",
    padding: "12px",
    borderRadius: "5px",
    background: "#ff6f61",
    color: "#fff",
    fontSize: "16px",
    fontWeight: "bold",
    border: "none",
    cursor: "pointer",
    marginTop: "10px",
    transition: "background 0.3s ease-in-out",
  },
  poweredByStripe: {
    marginTop: "15px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "8px",
    fontSize: "14px",
    fontWeight: "600",
  },
  stripeLogo: {
    width: "70px",
    height: "40px",
    objectFit: "contain",
  },
};
