import { Button, Card, Spinner } from 'react-bootstrap';
import formatCurrency from '../utils/currencyFormmter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import { getOrgVAT } from '../redux/slices/inventory.slice';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { removeFromCart, updateQuantity } from '../redux/slices/cartSlice.slice';
import "../pages/inventory/scss/Storefront.scss"

const Cart = ({cartItems, handleSubmit, requestStatus, printReceipt, clearCart, handleInput, orderNumber}) => {

    const isLoaded = useRef(false)
    const totalPrice = cartItems.reduce((sum, item) => sum + (item.sales_price * item.quantity), 0)
    const [vat, setVAT] = useState(0)
    const { business } = useSelector((state) => state.user)

  useEffect(() => {
    if(!isLoaded.current) {
        getVat()
        isLoaded.current = true
    }
  }, [])

  const dispatch = useDispatch()

  const vatAmount = totalPrice * (vat/100);

    const handleRemoveFromCart = (id) => {
        dispatch(removeFromCart(id));
    };

    const handleQauntityUpdate = (id, quantity) => {
        if (quantity <= 0) {
            handleRemoveFromCart(id);
        } else {
        dispatch(updateQuantity({ id, quantity }));
        }
    };

    const getVat = () => {
        dispatch(getOrgVAT())
        .then((response) => {
            if(response.payload?.status) {
              setVAT(response.payload.data)
            }
        })
    }

    const handlePrint = () => {
        const currentDate = new Date().toLocaleString();
    
        const receiptContent = `
            <div class="header">
                <img src="${business?.profile_image}" alt="Company Logo" class="logo" width="70">
                <h1>${business?.store_name}</h1>
                <p>${business?.store_address}</p>
                <p>Phone: ${business?.phone_number}</p>
            </div>
            
            <div class="receipt-info">
                <p><strong>Order #: ${orderNumber}</strong></p>
                <p>Date: ${currentDate}</p>
            </div>
            
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    ${cartItems.map(
                        (item) => `
                        <tr>
                            <td>${item.name}</td>
                            <td>${formatCurrency(item.quantity).formatted}</td>
                            <td>${formatCurrency(item.sales_price * item.quantity).with_currency}</td>
                        </tr>
                        `
                    ).join("")}
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2">Subtotal</td>
                        <td>${formatCurrency(totalPrice).with_currency}</td>
                    </tr>
                    <tr>
                        <td colspan="2">VAT (${vat}%)</td>
                        <td>${formatCurrency(vatAmount).with_currency}</td>
                    </tr>
                    <tr class="total">
                        <td colspan="2"><strong>Total</strong></td>
                        <td><strong>${formatCurrency(totalPrice + vatAmount).with_currency}</strong></td>
                    </tr>
                </tfoot>
            </table>
            
            <div class="footer">
                <p>Thank you for your purchase!</p>
            </div>
        `;
    
        const printWindow = window.open("", "", "width=400,height=600");
        printWindow.document.write(`
            <html>
                <head>
                    <title>Receipt - ${orderNumber}</title>
                    <style>
                        body {
                            font-family: 'Courier New', monospace;
                            margin: 20px;
                            max-width: 300px;
                            font-size: 14px;
                            line-height: 1.4;
                        }
                        .header {
                            text-align: center;
                            border-bottom: 2px dashed #000;
                            padding-bottom: 10px;
                            margin-bottom: 10px;
                        }
                        .logo {
                            max-width: 100px;
                            margin: 0 auto;
                            display: block;
                        }
                        h1 {
                            font-size: 20px;
                            margin: 10px 0;
                        }
                        .receipt-info {
                            margin: 10px 0;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            margin: 15px 0;
                        }
                        th, td {
                            padding: 5px;
                            text-align: left;
                        }
                        th {
                            background-color: #f0f0f0;
                            border-bottom: 2px solid #000;
                        }
                        td {
                            border-bottom: 1px dotted #666;
                        }
                        tfoot td {
                            padding-top: 10px;
                        }
                        .total {
                            font-weight: bold;
                            border-top: 2px solid #000;
                        }
                        .footer {
                            text-align: center;
                            border-top: 2px dashed #000;
                            padding-top: 10px;
                            margin-top: 10px;
                        }
                    </style>
                </head>
                <body>
                    ${receiptContent}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };

    const handleEntry = (e) => {
        handleInput(e)
    }
    

  return (
    <>
        <Card className='border-0 shadow-none'>
            <Card.Header as="h5" className='bg-white text-center no-print'>Your Cart</Card.Header>
            <Card.Body>
                {!printReceipt && cartItems.length > 0 ? (
                    <div className="cart-items cart no-print">
                        <table>
                            <thead>
                            <tr>
                                <th>Product</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {cartItems.map((item,index) => (
                                <tr key={item.id}>
                                <td>{index+1}. {item.name}</td>
                                <td>
                                    <input
                                    type="number"
                                    name="quantity"
                                    value={item.quantity}
                                    onChange={(e) =>
                                        handleQauntityUpdate(item.id, parseInt(e.target.value))
                                    }
                                    min="1"
                                    />
                                </td>
                                <td>{formatCurrency(item.sales_price).with_currency}</td>
                                <td>
                                    <button
                                    className="btn-plain btn-sm text-danger"
                                    onClick={() => handleRemoveFromCart(item.id)}
                                    >
                                    <FontAwesomeIcon icon={"times-circle"} />
                                    </button>
                                </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div className="total-area mt-2">
                            <p className="text-end">
                            <strong>SubTotal: {formatCurrency(totalPrice).with_currency}</strong>
                            </p>
                            <p className="text-end">
                            <strong>VAT {vat}%: {formatCurrency(vatAmount).with_currency}</strong>
                            </p>
                            <p className="text-end">
                            <strong>Total: {formatCurrency(totalPrice + vatAmount).with_currency}</strong>
                            </p>
                        </div>
                        <div className="mt-6 d-flex justify-content-end gap-4">
                            <div className="p-0">
                                <label> Payment Method</label> 
                                <select
                                    name="payment_method"
                                    required
                                    className='ms-2c form-control form-control-sm'
                                    onChange={handleEntry}
                                >
                                    <option value="">--Select--</option>
                                    <option value="CASH">Cash</option>
                                    <option value="DEBIT CARD">Card</option>
                                    <option value="BANK TRANSFER">Bank Transfer</option>
                                    <option value="PAYCODE VOUCHER">Paycode Voucher</option>
                                </select>
                            </div>
                        </div>
                        <Button 
                            variant="primary" 
                            type='button' 
                            className="w-100 mt-3"
                            onClick={handleSubmit}
                        >
                            Checkout {requestStatus && <Spinner size='sm' /> }
                        </Button>
                    </div>
                ) : !printReceipt && (
                    <p className='text-center'>Your cart is empty</p>
                )}

                {
                    printReceipt && (
                        <div className="cart-items cart printarea">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cartItems.map((item,index) => (
                                        <tr key={item.id}>
                                            <td>{index+1}. {item.name}</td>
                                            <td>
                                                x{formatCurrency(item.quantity).formatted}
                                            </td>
                                            <td>{formatCurrency(item.sales_price).with_currency}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="total-area mt-2">
                                <p className="text-end">
                                    <strong>SubTotal: {formatCurrency(totalPrice).with_currency}</strong>
                                </p>
                                <p className="text-end">
                                    <strong>VAT {vat}%: {formatCurrency(vatAmount).with_currency}</strong>
                                </p>
                                <p className="text-end">
                                    <strong>Total: {formatCurrency(totalPrice + vatAmount).with_currency}</strong>
                                </p>
                            </div>
                        </div>
                    ) 
                }
            </Card.Body>
            {
                printReceipt && (
                    <Card.Footer className='bg-white border-0 d-flex justify-content-between'>
                        <Button type='button' variant='primary' className='no-print' onClick={clearCart}>
                            Back to store 
                        </Button>
                        <Button variant='primary' type='button' className='no-print' onClick={handlePrint}>
                            Print  <FontAwesomeIcon icon={'print'} style={{"fontSize": "20px"}} />
                        </Button>
                    </Card.Footer>
                )
            }
        </Card>
    </>
  );
};

export default Cart