import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";

import ManagerService from "../../services/manager.service";

const user = JSON.parse(localStorage.getItem("user"));


export const getProfile = createAsyncThunk(
  "auth/user/",
  async(thunkAPI) => {
    try {
      const response = await ManagerService.getProfile();
      // console.log('Profile', response[0])
      return response[0];
    } catch (error) {
      console.log(error)
      return error
    }
  }
)

export const verification = createAsyncThunk(
  "auth/verify/",
  async ({ email, otp }, thunkAPI) => {
    try {
      const payload = { email, otp }
      const response = await ManagerService.verification(payload);
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}));
      thunkAPI.dispatch(memberAuthSlice.actions.setVerificationStatus(response.data))
      return response.data;
    } catch (error) { 
      console.log(error)
      const message =
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message: message, status: error.status || error.success}));
      thunkAPI.dispatch(memberAuthSlice.actions.hasError(error))
      return thunkAPI.rejectWithValue();
    }
  }
);

export const resendOtp = createAsyncThunk(
  "auth/re_verify/",
  async ({ email }, thunkAPI) => {
    try {
      const payload = { email }
      const response = await ManagerService.resendOtp(payload);
      thunkAPI.dispatch(setMessage(response.data.message));
      return response.data;
    } catch (error) { 
      console.log(error)
      const message =
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message: message, status: error.status || error.success}));
      thunkAPI.dispatch(memberAuthSlice.actions.hasError(error))
      return thunkAPI.rejectWithValue();
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "/members/forgot-password/",
  async (payload, thunkAPI) => {
    try {
      // const payload = { email }
      const response = await ManagerService.forgotPassword(payload);
      // console.log(response)
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}));
      return response;
    } catch (error) { 
      console.log(error)
      const message =
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message: message, status: error.status || error.success}));
      thunkAPI.dispatch(memberAuthSlice.actions.hasError(error))
      return error
    }
  }
);

export const resetPassword = createAsyncThunk(
  "/members/forgot_password_validate/",
  async ({ email, password, otp }, thunkAPI) => {
    try {
      const payload = { email, password, otp }
      const response = await ManagerService.resetPassowrd(payload);
      // console.log(response)
      thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}));
      return response;
    } catch (error) { 
      console.log(error)
      const message =
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage({message: message, status: error.status || error.success}));
      thunkAPI.dispatch(memberAuthSlice.actions.hasError(error))
      return error
    }
  }
);

export const login = createAsyncThunk(
  "member/login/",
  async ({ email, password }, thunkAPI) => {
    try {
      const payload = { email, password }
      const response = await ManagerService.login(payload);
      thunkAPI.dispatch(memberAuthSlice.actions.setAuthStatus(response.data))
      // thunkAPI.dispatch(setMessage({message: response.message, status: 'True'}))
      return response;
    } catch (error) {
        // console.log(error)
        const message =
        error.message ||
        error.toString();
        thunkAPI.dispatch(setMessage({message: message, status: 'False' }));
      return error;
      return thunkAPI.rejectWithValue();
    }
  }
);

export const changeAccountPassword = createAsyncThunk(
  '/members/change_password/',
  async(payload, thunkAPI) => {
    try {
        const response = await ManagerService.changePassword(payload)
        return response
      } catch (error) {
        console.log(error)
        return error
    }
  }
)

export const logout = createAsyncThunk(
  "auth/logout", 
  async (_, thunkAPI) => {
    thunkAPI.dispatch(memberAuthSlice.actions.signOut())
    return true
  });

const initialState =
 { 
    isLoggedIn: false, 
    user: null, 
    isLoading: false,
    // isOtpVerified: false,
    // isAccountCreated: false,
    error: null,
    isManager: false
  };

const memberAuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },
    startLoading(state) {
      state.isLoading = true
    },
    getSignupSuccess(state, action) {
      state.user = action.payload
      state.isAccountCreated = true
      state.isOtpVerified = false
    },
    setVerificationStatus(state, action) {
      state.isOtpVerified = true
      state.isAccountCreated = true
      state.user = action.payload
      state.isLoading = false
      localStorage.setItem('tkn', action.payload.token)
    },
    setAuthStatus(state, action) {
      state.user = action.payload
      state.isLoggedIn = true
      state.isManager = true
      localStorage.setItem('tkn', action.payload.token)
    },
    setToken(state, action) {
      localStorage.setItem("tkn", action.payload); 
    },
    signOut(state) {
      state.isLoggedIn = false
      state.isManager = false
      localStorage.removeItem('tkn')
    },
    setUser(state, action) {
      state.user = action.payload;
    }

  },
  extraReducers: {
    [logout.fulfilled]: (state, action) => {
      state.isLoggedIn = false;
      state.isManager = false;
      localStorage.removeItem('tkn')
      localStorage.removeItem('invLogin')
      localStorage.removeItem('invhash')
    },
    [getProfile.fulfilled]: (state, action) => {
      state.user = action.payload
    },
    // [login.fulfilled]: (state, action) => {
    //   state.user = action.payload.data ?? ''
    //   state.isLoggedIn = true
    //   state.isManager = false
    //   if(action.payload.data?.token) {
    //     localStorage.setItem('token', action.payload?.data?.token)
    //   }
    //   else {
    //     localStorage.removeItem('token')
    //   }
    // }
  },
});

const { reducer } = memberAuthSlice;
export default reducer;