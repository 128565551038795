import React, { useEffect, useRef, useState } from "react";
import "./scss/Storefront.scss";
import Layout from "../../components/Layout";
import { Container, Col, Row, Card, Button, Form, Modal, FormGroup, Spinner, ButtonGroup, InputGroup, Image} from "react-bootstrap";
import { createProduct, deleteProduct, getOrgVAT, getStoreProducts, importProduct, loginCustomer, productUpload, updateProduct, updateStorefrontItem, vatManagement } from "../../redux/slices/inventory.slice";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import template from "../../assets/Product_template.xlsx";
import AmountInput from "../../components/AmountInput";
import InventoryConnectionButton from "../../components/InventoryConnector";

const Products = () => {

  const isLoaded = useRef(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { invLogin } = useSelector((state) => state.inventory)
  const { business } = useSelector((state) => state.user)

    const navigate = useNavigate();

    const initialState = {
        loading: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            type: "storefront",
            quantity: 1,
            unit: "pcs",
            vendor_id: 1,
            restock_level: 1,
            supply_date: new Date(),
            expiry_date: new Date(),
        },
        errors: {},
        suppliers: [],
        products: [],
        restockMode: false,
        product: {},
        pricelist: [],
        product_file: '',
        showEditForm: false,
        showVatForm: false,
        vat: 0,
        isImport: false,
    }

  const [state, setState] = useState(initialState)
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    page: 1,
    status: '',
    filterBy: '',
    sortBy: 'desc',
    filter: '',
    orderBy: '',
  })


  useEffect(() => {
    if (!isLoaded.current) {
        getVat();
        loadProducts()
      isLoaded.current = true;
    }
  }, []);

  useEffect(() => {
    if (invLogin === true) {
      loadProducts();
    }
  }, [invLogin]);

  const loadProducts = () => {
    setLoading(true)
    dispatch(getStoreProducts(payload))
    .then((response) => {
      setProducts(response.payload?.data.data);
    })
    .catch((error) => {
      console.error('Error fetching data: ', error);
    })
    .finally(() => setLoading(false))
  }

    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: newValue}}))
        }
        else {
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;

        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setState((prevState) => ({ ...prevState, errors:{...prevState.errors, [name]: `${name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} is required` }}))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setState((prevState) => ({ ...prevState, errors: { ...prevState.errors, [name]: 'Please enter a valid email address'}}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setState((prevState) => ({ ...prevState, errors: {...prevState.errors, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }}))
        }
        else {
            event.target.style = "border-style: transparent"
            delete state.errors[name]
            setState((prevState) => ({ ...prevState, errors: state.errors }))
        }
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            if(file.name.split('.').pop() === 'xlsx' || file.name.split('.').pop() === 'xls') {
                setState((prevState) => ({...prevState,  product_file: file, isImport: true}))
            }
            else{
                setState((prevState) => ({...prevState,  image: file, product_image: reader.result, isImport: false}))
            }
        };
    }

    const uploadProduct = () => {
        let formdata = new FormData()
        formdata.append('product_file', state.product_file)
        dispatch(importProduct(formdata))
        .then((response) => {
            if(response.payload?.status && response.payload?.data) {
                Swal.fire({
                    icon: 'success',
                    text: response?.payload?.message
                })
                .then(() => {
                    setState((prevState) => ({...prevState, showStockForm: false,  product_file: '', payload: initialState.payload}))
                })
            }
            else {
                Swal.fire({
                    icon: 'error', 
                    text: response?.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const addStoreProduct = () => {
        setLoading(true)
        if(state.isImport) {
            return uploadProduct()
        }
        let formdata = new FormData()
        formdata.append('name', state.payload.product_name)
        formdata.append('price', state.payload.price)
        formdata.append('sales_price', state.payload.sales_price)
        formdata.append('description', state.payload.description)
        formdata.append('sku', state.payload.sku)
        formdata.append('image', state.image)

        dispatch(createProduct(formdata))
        .then((response) => {
            if(response.payload?.status && response.payload?.data) {
                Swal.fire({
                    icon: 'success',
                    text: response?.payload?.message
                })
                .then(() => {
                    setState((prevState) => ({...prevState, showStockForm: false,  image: '', product_image: '', payload: initialState.payload}))
                })
            }
            else {
                Swal.fire({
                    icon: 'error', 
                    text: response?.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const updateStock = () => {
        let formdata = new FormData()
        formdata.append('id', state.product.id)
        formdata.append('name', state.product.name)
        formdata.append('price', state.product.price)
        formdata.append('sales_price',state.product.sales_price)
        formdata.append('description', state.product.description)
        formdata.append('sku', state.product.sku)
        formdata.append('image', state.image)
        setLoading(true)
        dispatch(updateStorefrontItem({id: state.product.id, payload: formdata}))
        .then((response) => {
            if(response.payload?.status) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload.message,
                })
                loadProducts()
                setState((prevState) => ({...prevState, showEditForm: false}))
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload.message,
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const editProduct = (product) => {
        setState((prevState) => ({...prevState, showEditForm: true, product}))
    }

    const removeProduct = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this product!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        })
        .then((result) => {
            if(result.isConfirmed) {
                setLoading(true)
                dispatch(deleteProduct(id))
                .then((response) => {
                    if(response.payload?.status) {
                        Swal.fire({
                            icon: 'success',
                            text: response.payload.message,
                        })
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            text: response.payload.message,
                        })
                    }
                })
                .finally(() => setLoading(false))
            }
        })
    }

    const serverSideSearch = (event) => {
        const value = event.target.value;

        if (value.length >= 3) {
            setLoading(true);
            dispatch(getStoreProducts(payload))
            .then((response) => {
                const result = response.payload?.data?.data
                if(result != null && result !== undefined) {
                    setProducts(result)
                }
                else {
                setProducts([])
                }
            })
            .finally(() => setLoading(false))
        }
        else if (value.length === 0) {
            loadProducts()
        }
    }

    const handleSearch = (event) => {
        const value = event.target.value;
        
        if (value.length >= 3) {
            setLoading(true);
            axios.get(`https://dummyjson.com/products/search?q=${value}`)
                .then((response) => {
                    setProducts(response.data.products);
                })
                .catch((error) => {
                    console.error('Error searching products:', error);
                })
                .finally(() => setLoading(false));
        } else if (value.length === 0) {
            loadProducts();
        }
    };

    const handleAmountUpdate = (data) => {
        const cleanAmount = data.split(',').join('')
        setPayload((prevState) => ({...prevState, vat: cleanAmount}))
    }

    const updateVAT = () => {
        setLoading(true)
        dispatch(vatManagement(payload))
        .then((response) => {
            if(response.payload?.status) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload.message,
                })
                .then(() => {
                    setState((prevState) => ({...prevState, showVatForm: false}))
                })
                getVat()
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload.message,
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const getVat = () => {
        setLoading(true)
        dispatch(getOrgVAT())
        .then((response) => {
            if(response.payload?.status) {
                setState((prevState) => ({...prevState, vat: response.payload.data}))
            }
            else {
                // Swal.fire({
                //     icon: 'error',
                //     text: response.payload.message,
                // })
            }
        })
        .finally(() => setLoading(false))
    }

    const sortProducts = (order) => {
        setPayload((prevState) => ({...prevState, sortBy: order}))
        loadProducts()
    }

  return (
    <Layout>
      <Container fluid className="mt-4">
        <InventoryConnectionButton />
        <Col md={12} className="row align-items-center justify-content-between mb-4">
            <div className="col-md-4">
                <h3 className="m-0">Storefront Products</h3>                      
            </div>
            <Col md={8} className="text-right">
                <Button 
                    type="button" 
                    className="border-0 mb-2 px-3"
                    onClick={ () => setState((prevState) => ({...prevState, showStockForm: !state.showStockForm}))}
                >
                    <FontAwesomeIcon icon={'plus'}/>
                    <span className="ps-2">Add Product</span>
                </Button>
                <Button 
                    type="button" 
                    className="border-0 mb-2 px-3 ms-2"
                    onClick={ () => setState((prevState) => ({...prevState, showVatForm: !state.showVatForm}))}
                >
                    <FontAwesomeIcon icon={'plus'}/>
                    <span className="ps-2">Manage VAT</span>
                </Button>
                <Button 
                    type="button" 
                    className="border-0 mb-2 px-3 ms-2"
                    onClick={ () => user.user_type === 'owner' ? navigate('/inventory/storefront') : navigate('/members/inventory/storefront')}
                >
                    <FontAwesomeIcon icon={"store"}/>
                    <span className="ps-2">Storefront</span>
                </Button>
            </Col>
        </Col>
        <Col md={8}>
            <div className="product-search">
              <div className="input-group">
                  <button 
                      type="button" 
                      className="input-group-text"
                  >
                      <FontAwesomeIcon icon={"search"} style={{fontSize: "20px"}}/>
                  </button>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onKeyUp={serverSideSearch}
                  />
                    <div className="input-group-text d-flex px-1">
                      <button 
                        type="button" 
                        className={`btn-plain ${payload.sortBy === 'desc' ? "bg-primary text-white rounded" : ""} `}
                        onClick={() => sortProducts("desc")}
                      >
                        <FontAwesomeIcon icon={'arrow-up-short-wide'} />
                      </button>
                      <button 
                        type="button" 
                        className={`btn-plain ms-2 ${payload.sortBy === 'asc' ? "bg-primary text-white rounded" : ""} `}
                        onClick={() => sortProducts("asc")}
                      >
                        <FontAwesomeIcon icon={'arrow-down-short-wide'} />
                      </button>
                    </div>
              </div>
            </div>
            <p className="text-center mt-3 text-primary">
                { loading && <Spinner size="sm" /> }
            </p>
        </Col>
        <Row xs={1} md={2} lg={4} className="g-4 mt-1">
            {products.map((product) => (
                <Col key={product.id}>
                    <Card className={`h-100 border-0 single-product`} >
                        <div 
                            className="d-flex justify-content-center align-items-center" 
                            style={{
                                height: '200px', 
                                overflow: 'hidden',
                                backgroundColor: '#ffffff'
                            }}
                        >
                            <Card.Img 
                                variant="top" 
                                src={product.image} 
                                style={{
                                    objectFit: 'contain', 
                                    width: 'auto',
                                    maxHeight: '100%',
                                    maxWidth: '100%'
                                }}
                                className="mx-auto" 
                                onError={(e) => {
                                    e.target.src = 'https://placehold.co/400x400'; 
                                }}
                            />
                        </div>
                        
                        <Card.Body className="d-flex flex-column">
                            <Card.Title className="text-center mb-2">{product.name}</Card.Title>
                            <Card.Text className="text-center text-primary fw-bold">
                                {formatCurrency(product.sales_price).with_currency}
                            </Card.Text>
                        </Card.Body>
                        {
                            user.user_type === 'owner' && (
                                <Card.Footer className="border-0 bg-white">
                                    <ButtonGroup>
                                        <button type="button" className="btn-plain text-primary btn-sm" onClick={() => editProduct(product)}>
                                            <FontAwesomeIcon icon={'edit'}/>
                                        </button>
                                        <button type="button" className="btn-plain btn-sm text-danger" onClick={() => removeProduct(product.id)}>
                                            <FontAwesomeIcon icon={'trash'}/>
                                        </button>
                                    </ButtonGroup>
                                </Card.Footer>
                            )
                        }
                    </Card>
                </Col>
            ))}
        </Row>
        {
            products.length === 0 && <Col md={12} className="text-center mt-5">No items available</Col>
        }
        <Modal 
            onHide={() => setState((prevState) => ({...prevState, showStockForm: false}))} 
            show={state.showStockForm} size="lg" 
            centered
            backdrop="static"
        >
            <Modal.Header className="p-1 bg-primary text-white">
                <Modal.Title>Add Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <p>You can upload a single product using this form or upload bulk through <a href={template} download={true} className="text-primary font-bold">file upload using the template here</a> </p>
                </div>
                <Row>
                    <FormGroup as={Col} md={6} sm={12} className="mb-3">
                        <span> Product Name</span> <span className="text-danger">*</span>
                        <Form.Control
                            type="text"
                            name="product_name"
                            onChange={handleInput}
                            onKeyUp={handleValidation}
                            placeholder="Enter your Product Name"
                            required
                        />
                        {
                            state.errors['product_name'] && 
                            <Form.Text className="text-danger">{state.errors['product_name']}</Form.Text>
                        }                            
                    </FormGroup>
                    <FormGroup as={Col} md={6} sm={12} className="mb-3">
                        <span>Sku/Code</span><span className="text-danger">*</span>
                        <Form.Control
                            type="text"
                            name="sku"
                            onChange={handleInput}
                            onKeyUp={handleValidation}
                            placeholder="SKU or product code"
                            required
                        />
                        {
                            state.errors['sku'] && 
                            <Form.Text className="text-danger">{state.errors['sku']}</Form.Text>
                        }                           
                    </FormGroup>
                    <FormGroup as={Col} md={6} sm={12} className="mb-3">
                        <span>Cost Price</span><span className="text-danger">*</span>
                        <Form.Control
                            type="number"
                            name="price"
                            onChange={handleInput}
                            onKeyUp={handleValidation}
                            placeholder="Cost Price"
                            min={0}
                            required
                        />
                        {
                            state.errors['price'] && 
                            <Form.Text className="text-danger">{state.errors['price']}</Form.Text>
                        }                           
                    </FormGroup>
                    <FormGroup as={Col} md={6} sm={12} className="mb-3">
                        <span>Sales price</span><span className="text-danger">*</span>
                        <Form.Control
                            type="number"
                            name="sales_price"
                            onChange={handleInput}
                            onKeyUp={handleValidation}
                            placeholder="Sales Price "
                            min={0}
                            required
                        />
                        {
                            state.errors['sales_price'] && 
                            <Form.Text className="text-danger">{state.errors['sales_price']}</Form.Text>
                        }                           
                    </FormGroup>
                    <FormGroup as={Col} md={12} sm={12} className="mb-3">
                        <span>Description</span><span className="text-danger">*</span>
                        <textarea
                            name="description"
                            onChange={handleInput}
                            onKeyUp={handleValidation}
                            placeholder="Product description"
                            className="form-control"
                            required
                        ></textarea>
                        {
                            state.errors['description'] && 
                            <Form.Text className="text-danger">{state.errors['description']}</Form.Text>
                        }                       
                    </FormGroup>
                    <FormGroup as={Col} md={12} sm={12} className="mb-3">
                        <span>Item Images</span><span className="text-danger">*</span>
                        <Form.Control
                            type="file"
                            onChange={handleFileSelect}
                            name="image"
                            accept="image/*"
                            multiple
                        />
                        <div className="mt-2 mb-2">
                            {
                                state.product_image ? (
                                    <img
                                        src={state.product_image}
                                        alt="Product Image"
                                        width="100"
                                        height="100"
                                        className="img-thumbnail"
                                    />
                                ) : null
                            }
                        </div>
                    </FormGroup>

                    <FormGroup as={Col} md={12} sm={12} className="mb-3">
                        <span>Upload product from file</span>
                        <Form.Control
                            type="file"
                            onChange={handleFileSelect}
                            name="product_file"
                            accept=".xlsx,.xls"
                        />
                    </FormGroup>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" type="button" className="text-white" onClick={() => setState((prevState) => ({...prevState, showStockForm: false}))}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={addStoreProduct}>
                    Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal 
            onHide={() => setState(prevState => ({...prevState, showEditForm: false}))} 
            show={state.showEditForm} 
            size="lg" 
            centered
            backdrop="static"
            aria-labelledby="edit-product-modal"
        >
            <Modal.Header closeButton className="p-2 bg-primary text-white">
                <Modal.Title id="edit-product-modal">Edit Product</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Row>
                        <Form.Group as={Col} md={6} sm={12} className="mb-3">
                            <Form.Label>
                                Product Name <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Enter product name"
                                required
                                value={state.product?.name || ''}
                                onChange={(e) => setState((prevState) => ({
                                    ...prevState,
                                    product: { 
                                        ...prevState.product, 
                                        [e.target.name]: e.target.value 
                                    }
                                }))} 
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} sm={12} className="mb-3">
                            <Form.Label>
                                SKU/Code <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="sku"
                                placeholder="Enter SKU or product code"
                                required
                                value={state.product?.sku}
                                onChange={(e) => setState((prevState) => ({
                                    ...prevState,
                                    product: { 
                                        ...prevState.product, 
                                        [e.target.name]: e.target.value 
                                    }
                                }))} 
                            />
                        </Form.Group>

                        <Form.Group as={Col} md={6} sm={12} className="mb-3">
                            <Form.Label>
                                Cost Price <span className="text-danger">*</span>
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    name="price"
                                    placeholder="0.00"
                                    min="0"
                                    step="0.01"
                                    required
                                    value={state.product?.price}
                                    onChange={(e) => setState((prevState) => ({
                                        ...prevState,
                                        product: { 
                                            ...prevState.product, 
                                            [e.target.name]: e.target.value 
                                        }
                                    }))} 
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md={6} sm={12} className="mb-3">
                            <Form.Label>
                                Sales Price <span className="text-danger">*</span>
                            </Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    name="sales_price"
                                    placeholder="0.00"
                                    min="0"
                                    step="0.01"
                                    required
                                    value={state.product?.sales_price}
                                    onChange={(e) => setState((prevState) => ({
                                        ...prevState,
                                        product: { 
                                            ...prevState.product, 
                                            [e.target.name]: e.target.value 
                                        }
                                    }))} 
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group as={Col} md={12} sm={12} className="mb-3">
                            <Form.Label>
                                Description <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="description"
                                placeholder="Enter product description"
                                required
                                value={state.product?.description || ''}
                                onChange={(e) => setState((prevState) => ({
                                    ...prevState,
                                    product: { 
                                        ...prevState.product, 
                                        [e.target.name]: e.target.value 
                                    }
                                }))} 
                            />
                            {state.errors.description && (
                                <Form.Text className="text-danger">{state.errors.description}</Form.Text>
                            )}
                        </Form.Group>

                        <Form.Group as={Col} md={12} sm={12} className="mb-3">
                            <Form.Label>Product Image</Form.Label>
                            {(state.formData?.image || state.product?.image) && (
                                <div className="mb-2">
                                    <Image
                                        src={state.product?.image}
                                        alt="Current product image"
                                        thumbnail
                                        width={100}
                                        height={100}
                                        className="img-thumbnail"
                                    />
                                    <div className="form-text">Current image</div>
                                </div>
                            )}
                            <Form.Control
                                type="file"
                                onChange={handleFileSelect}
                                name="image"
                                accept="image/*"
                                className="d-none"
                                id="productImageUpload"
                            />
                            <Button 
                                variant="primary" 
                                size="sm" 
                                onClick={() => document.getElementById('productImageUpload').click()}
                            >
                                {state.product?.image ? 'Change Image' : 'Upload Image'}
                            </Button>
                        </Form.Group>
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    variant="outline-secondary" 
                    onClick={() => setState(prevState => ({...prevState, showEditForm: false}))}
                >
                    Cancel
                </Button>
                <Button 
                    variant="primary" 
                    onClick={updateStock}
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            <span className="ms-2">Saving...</span>
                        </>
                    ) : 'Save Changes'}
                </Button>
            </Modal.Footer>
        </Modal>
        
        <Modal 
            onHide={() => setState((prevState) => ({...prevState, showVatForm: false}))} 
            show={state.showVatForm} size="sm" 
            centered
            backdrop="static"
        >
            <Modal.Header className="p-1 bg-primary text-white">
                <Modal.Title>Manage VAT</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <FormGroup as={Col} md={12} sm={12} className="mb-3">
                        <span>VAT Percentage Amount</span> <span className="text-danger">*</span>
                        <AmountInput onChange={handleAmountUpdate} />       
                        <p>Current VAT: <strong>{state.vat}%</strong></p>                  
                    </FormGroup>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" type="button" className="text-white" onClick={() => setState((prevState) => ({...prevState, showVatForm: false}))}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={updateVAT}>
                    Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                </Button>
            </Modal.Footer>
        </Modal>
      </Container>
    </Layout>
  );
};

export default Products;