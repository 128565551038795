import { Row, Col, Container, Card, Table, Spinner, Button, FormGroup, Form} from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import { useNavigate } from "react-router-dom";
import { createSales, getProductPrices, getProducts, loginCustomer, searchProduct } from "../../redux/slices/inventory.slice";
import { debounce, filter } from "lodash";
import InventoryConnectionButton from "../../components/InventoryConnector";

const SellProduct = () => {

    const isLoaded = useRef(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialState = {
        loading: false,
        show_vendor_form: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            to_currency: 'NGN', 
            currency: 'USD', 
            amount: 1,
            page: 1,
            from_date: '',
            to_date: '',
            status: '',
            download: '',
            filter_by: '',
            sort_by: '',
            filter: '',
            gateway: 'FasePay',
        },
        errors: {},
        suppliers: [],
        products: [],
        restockMode: false,
        product: {},
        pricelist: [],
    }

    const [state, setState] = useState(initialState)
    const [loading, setLoading] = useState(false)

    const { invLogin } = useSelector((state) => state.inventory)
    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)

    const [searchTerm, setSearchTerm] = useState('');
    const [products, setProducts] = useState([]);
    const [error, setError] = useState(null);
    
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [quantity, setQuantity] = useState(1);
    const [cartItems, setCartItems] = useState([]);
    const [showReceipt, setShowReceipt] = useState(false);
    const [receiptData, setReceiptData] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        if(!isLoaded.current) {
            isLoaded.current = true
            loadProducts()
        }
    }, [])

    const handleInput = (event) => {
        const {name, value} = event.target
         if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: newValue}}))
        }
        else {
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
        }
    }

    const handleSearch = () => {
        loadProducts()
    }

    const loadProducts = () => {
        setLoading(true)
        dispatch(getProductPrices())
        .then((response) => {
            const result = response.payload?.data?.data
            if(result) {
                setState((prevState) => ({...prevState, products:  result})) 
                setProducts(result)
            }
        })
        .finally(() => setLoading(false))
    }

    const serverSideSearch = () => {
        if (!searchTerm.trim()) {
          setProducts([]);
          return;
        }
        
        setLoading(true);
        dispatch(searchProduct(searchTerm))
        .then((response) => {
            const result = response.payload?.data?.data
            if(result != null && result !== undefined) {
                setState((prevState) => ({...prevState, products:  result}))
                setProducts(result)
                setFilteredProducts(result)
            }
            else {
                setState((prevState) => ({...prevState, products:  []}))
                setProducts([])

            }
        })
        .finally(() => setLoading(false))
    }

    const debouncedFetchProducts = useCallback(
        debounce(async (searchQuery) => {
          if (!searchQuery.trim()) {
            setProducts([]);
            return;
          }
    
          try {
            setLoading(true);
            setError(null);
    
            const data = await dispatch(getProductPrices)
            // setProducts(data.payload?.data);
            // console.log("PRODUCTS", data.payload)
          } catch (err) {
            setError('Error fetching products. Please try again.')
            setProducts([])
          } finally {
            setLoading(false)
          }
        }, 300), // Wait 300ms after last keystroke before making API call
        [] // Empty dependency array since we don't want to recreate the debounced function
    )

    const filteredProduct = () => {
      const results =  products.filter(product =>
            product.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
        setFilteredProducts(results)
    } 

    const addToCart = () => {
        if (!selectedProduct) return;

        if (quantity == 0) return;

        if(selectedProduct.quantity < quantity) {
            Swal.fire({
                icon: 'error',
                text: 'Insufficient stock',
            })
            return;
        }

        const newItem = {
            ...selectedProduct,
            qty: quantity,
            total: selectedProduct.sales_price * quantity
        };

        setCartItems([...cartItems, newItem]);
        setSelectedProduct(null);
        setQuantity(1);
        setSearchTerm('');
    }

    const removeFromCart = (index) => {
        const newCart = cartItems.filter((_, i) => i !== index);
        setCartItems(newCart)
    }

    const getTotal = () => {
        return cartItems.reduce((sum, item) => sum + item.total, 0);
    }

    const handleSubmit = () => {

        if (cartItems.length === 0) return;

        if (state.payload.payment_method === undefined || state.payload.payment_method === '') return;

        const receipt = {
            items: cartItems,
            total: getTotal(),
            date: new Date().toLocaleString(),
            receiptNumber: Math.random().toString(36).substr(2, 9)
        }

        const payload = {
            cart: cartItems,
            payment_method: state.payload.payment_method,
            type: "bulk"
        }

        setLoading(true)
        dispatch(createSales(payload))
        .then((response) => {
            if(response.payload?.status) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload.message,
                })
                .then(() => {
                    setReceiptData(receipt)
                    setShowReceipt(true)
                    setCartItems([])
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload.message,
                })
            }
        })
        .finally(() => setLoading(false))
    }

    const handleCancel = () => {
        setCartItems([])
        setSelectedProduct(null)
        setQuantity(1)
        setSearchTerm('')
    }

    const SelectedProduct = (product) =>  {
        setSelectedProduct(product)
        setSearchTerm('')
    }

    const handlePrint = () => {
        const currentDate = new Date().toLocaleString();
    
        const receiptContent = `
            <div class="header">
                <img src="${business?.profile_image}" alt="Company Logo" class="logo" width="70">
                <h1>${business?.store_name}</h1>
                <p>${business?.store_address}</p>
                <p>Phone: ${business?.phone_number}</p>
            </div>
            
            <div class="receipt-info">
                <p><strong>Order #: ${receiptData.receiptNumber}</strong></p>
                <p>Date: ${currentDate}</p>
            </div>
            
            <table>
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Qty</th>
                        <th>Price</th>
                    </tr>
                </thead>
                <tbody>
                    ${receiptData.items.map(
                        (item) => `
                        <tr>
                            <td>${item.name}</td>
                            <td>${formatCurrency(item.quantity).formatted}</td>
                            <td>${formatCurrency(item.sales_price * item.quantity).with_currency}</td>
                        </tr>
                        `
                    ).join("")}
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2">Subtotal</td>
                        <td>${formatCurrency(receiptData.total).with_currency}</td>
                    </tr>
                    <tr class="total">
                        <td colspan="2"><strong>Total</strong></td>
                        <td><strong>${formatCurrency(receiptData.total).with_currency}</strong></td>
                    </tr>
                </tfoot>
            </table>
            
            <div class="footer">
                <p>Thank you for your purchase!</p>
            </div>
        `;
    
        const printWindow = window.open("", "", "width=400,height=600");
        printWindow.document.write(`
            <html>
                <head>
                    <title>Receipt - ${receiptData.receiptNumber}</title>
                    <style>
                        body {
                            font-family: 'Courier New', monospace;
                            margin: 20px;
                            max-width: 300px;
                            font-size: 14px;
                            line-height: 1.4;
                        }
                        .header {
                            text-align: center;
                            border-bottom: 2px dashed #000;
                            padding-bottom: 10px;
                            margin-bottom: 10px;
                        }
                        .logo {
                            max-width: 100px;
                            margin: 0 auto;
                            display: block;
                        }
                        h1 {
                            font-size: 20px;
                            margin: 10px 0;
                        }
                        .receipt-info {
                            margin: 10px 0;
                        }
                        table {
                            width: 100%;
                            border-collapse: collapse;
                            margin: 15px 0;
                        }
                        th, td {
                            padding: 5px;
                            text-align: left;
                        }
                        th {
                            background-color: #f0f0f0;
                            border-bottom: 2px solid #000;
                        }
                        td {
                            border-bottom: 1px dotted #666;
                        }
                        tfoot td {
                            padding-top: 10px;
                        }
                        .total {
                            font-weight: bold;
                            border-top: 2px solid #000;
                        }
                        .footer {
                            text-align: center;
                            border-top: 2px dashed #000;
                            padding-top: 10px;
                            margin-top: 10px;
                        }
                    </style>
                </head>
                <body>
                    ${receiptContent}
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <InventoryConnectionButton />
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd no-print">
                    <div className="col-md-4">
                        <h3 className="m-0">Sales</h3>
                    </div>
                </Col>

                <div className="mt-4">
                    {
                        !showReceipt && (
                            <Row className="align-items-center justify-content-between">
                                <div className="col-12">
                                    <div className="p-6 shadow-md mb-6">
                                        <div className="d-flex align-items-center mb-4 gap-3">
                                            <div className="w-50 product-search">
                                                <div className="input-group">
                                                    {/* <button type="button" className="input-group-text">
                                                        <FontAwesomeIcon icon={"search"} style={{fontSize: "20px"}}/>
                                                    </button> */}
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        value={searchTerm}
                                                        onChange={(e) => setSearchTerm(e.target.value)}
                                                        onKeyUp={filteredProduct}
                                                    />
                                                    <button 
                                                        type="button" 
                                                        className="input-group-text bg-primary text-white"
                                                        onClick={serverSideSearch}
                                                    >
                                                        <FontAwesomeIcon icon={"search"} style={{fontSize: "20px"}}/>
                                                    </button>
                                                </div>
                                                {searchTerm && (
                                                    <div className="mt-2 border bg-white product-search-results">
                                                        {
                                                            filteredProducts.map(product => (
                                                                <div
                                                                    key={product.id}
                                                                    className="p-2 item"
                                                                    onClick={() => SelectedProduct(product)}
                                                                >
                                                                    {product.name} - {formatCurrency(product.sales_price).with_currency}
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                        
                                            <div>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Qty"
                                                    min="0"
                                                    // value={quantity}
                                                    onChange={(e) => setQuantity(parseInt(e.target.value) || 0)}
                                                />
                                            </div>
                                        
                                            <button
                                                className="btn-plain bg-primary text-white py-1 px-3 rounded"
                                                onClick={addToCart}
                                                disabled={!selectedProduct}
                                            >
                                                <FontAwesomeIcon icon={"plus"} style={{fontSize: "24px"}} />
                                            </button>
                                        </div>

                                        {selectedProduct && (
                                            <div className="p-3 bg-white mb-2 rounded-lg w-auto">
                                                Selected: {selectedProduct.name} - Price: {formatCurrency(selectedProduct.sales_price).with_currency}
                                                <br />
                                                Total: {formatCurrency(selectedProduct.sales_price * quantity).with_currency}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Row>
                        )
                    }

                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Body className="table-responsive-lg">
                            {!showReceipt ? (
                                <>
                                    <Table size="sm" className="table-color table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Product</th>
                                                    <th>Price(₦)</th>
                                                    <th>Quantity</th>
                                                    <th>Total(₦)</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className="data-container">
                                                {
                                                    loading ? (
                                                        <tr className="in-page-loader bg-transparent">
                                                          <td colSpan={4} className="bg-transparent">
                                                            <Spinner size="lg" variant="primary"/>
                                                          </td>  
                                                        </tr>
                                                    ) : null
                                                }
                                                {
                                                    cartItems?.length ? (
                                                        cartItems?.map((item, index) => (
                                                            <tr key={index}> 
                                                                <td className="py-1 px-0 font-bold">{index+1}. {item.name}</td>
                                                                <td className="py-1 px-0">{formatCurrency(item.sales_price).with_currency} </td>
                                                                <td className="py-1 px-0">{formatCurrency(item.qty).formatted} </td>
                                                                <td className="py-1 px-0">{formatCurrency(item.sales_price * item.qty).with_currency} </td>
                                                                <td className="p-2 text-left">
                                                                    <button
                                                                        className="text-danger btn-plain"
                                                                        onClick={() => removeFromCart(index)}
                                                                        title="Remove"
                                                                    >
                                                                        <FontAwesomeIcon icon={"close"} style={{fontSize: "18px"}} />
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    )
                                                    : 
                                                    (
                                                        <tr>
                                                            <td colSpan={5} className="text-center text-muted">No data found</td>
                                                        </tr>
                                                    )
                                                }
                                                {cartItems.length > 0 && (
                                                    <tr className="font-bold">
                                                        <td colSpan="3" className="p-2 text-right">Grand Total:</td>
                                                        <td className="p-2">{formatCurrency(getTotal()).with_currency}</td>
                                                        <td></td>
                                                    </tr>
                                                )}
                                            </tbody>
                                    </Table>
                                    <div className="mt-6 d-flex justify-content-end gap-4">
                                        <FormGroup as={Col} md={3} sm={12} className="mb-3">
                                            <span> Payment Method</span> 
                                            <Form.Select
                                                name="payment_method"
                                                onChange={handleInput}
                                                required
                                            >
                                                <option value="">--Select--</option>
                                                <option value="Cash">Cash</option>
                                                <option value="Debit Card">Card</option>
                                                <option value="Bank Transfer">Bank Transfer</option>
                                                <option value="Paycode Voucher">Paycode Voucher</option>
                                            </Form.Select>
                                        </FormGroup>
                                    </div>
                                    <div className="mt-6 d-flex justify-content-end gap-4">
                                        <Button
                                            type="button"
                                            className="px-4 py-2 me-3"
                                            onClick={handleCancel}
                                            variant="light"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            className="px-4 py-2"
                                            onClick={handleSubmit}
                                            disabled={cartItems.length === 0 || state.payload.payment_method === ''}
                                            variant="primary"
                                        >
                                            Submit Sale
                                        </Button>
                                    </div>
                                </>
                            ) : 
                            (
                                <div className="bg-white p-6 rounded-lg shadow-md">
                                    <div className="printarea">
                                        <div className="text-center mb-4">
                                            <img src={business?.profile_image} alt="logo" width={60} />
                                            <h2 className="text-xl font-bold">Sales Receipt</h2>
                                            <p className="text-gray-600">Receipt #{receiptData.receiptNumber}</p>
                                            <p className="text-gray-600">{receiptData.date}</p>
                                        </div>

                                        <table className="w-100 mb-4">
                                            <thead>
                                                <tr className="border-b">
                                                    <th className="p-2 text-left">Product</th>
                                                    <th className="p-2 text-right">Price</th>
                                                    <th className="p-2 text-right">Qty</th>
                                                    <th className="p-2 text-right">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {receiptData.items.map((item, index) => (
                                                    <tr key={index} className="border-b">
                                                    <td className="p-2">{item.name}</td>
                                                    <td className="p-2 text-right">{formatCurrency(item.sales_price).with_currency}</td>
                                                    <td className="p-2 text-right">{formatCurrency(item.qty).formatted}</td>
                                                    <td className="p-2 text-right">{formatCurrency(item.total).with_currency}</td>
                                                    </tr>
                                                ))}
                                                <tr className="font-bold">
                                                    <td colSpan="3" className="p-2 text-right">Total:</td>
                                                    <td className="p-2 text-right">{formatCurrency(receiptData.total).with_currency}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="flex justify-center gap-4 no-print">
                                        <button
                                            className="px-4 py-2 btn-plain bg-primary text-white rounded"
                                            onClick={handlePrint}
                                        >
                                            <FontAwesomeIcon icon={"print"} style={{fontSize: "20px"}} />
                                            Print Receipt
                                        </button>
                                        <button
                                            className="px-4 py-2 rounded btn-plain bg-primary text-white ms-2"
                                            onClick={() => setShowReceipt(false)}
                                        >
                                            Back to Sale
                                        </button>
                                    </div>
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
}

export default SellProduct;