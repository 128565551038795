import React, { useEffect, useRef, useState } from "react";
import "./scss/Storefront.scss";
import Layout from "../../components/Layout";
import { Container, Col, Row, Card, Button, Spinner} from "react-bootstrap";
import formatCurrency from "../../utils/currencyFormmter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createOrder, getStoreProducts } from "../../redux/slices/inventory.slice";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, clearCart, removeFromCart, initializeCartSession} from "../../redux/slices/cartSlice.slice";
import Swal from "sweetalert2";
import FloatingCart from "../../components/FloatingCart";
import Cart from "../../components/Cart";
import InventoryConnectionButton from "../../components/InventoryConnector";

const Storefront = () => {

  const isLoaded = useRef(false);
  const dispatch = useDispatch();
  const { items: cart, sessionId } = useSelector((state) => state.cart);

  useEffect(() => {
    dispatch(initializeCartSession());
  }, [dispatch]);


  // const { invLogin = false } = useSelector((state) => state.inventory)


  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({ sortBy: "desc" })
  const [sending, setSending] = useState(false)
  const [printReceipt, setPrintReceipt] = useState(false)
  const [orderNumber, setOrderNumber] = useState()
  const totalPrice = cart.reduce((sum, item) => sum + (item.price * item.quantity), 0)


  useEffect(() => {
    if (!isLoaded.current) {
      loadProducts();
      isLoaded.current = true;
    }
  }, []);

  const loadProducts = () => {
    setLoading(true)
    dispatch(getStoreProducts(payload))
    .then((response) => {
      setProducts(response.payload?.data.data);
    })
    .catch((error) => {
      console.error('Error fetching data: ', error);
    })
    .finally(() => setLoading(false))
  }

  const checkout = () => {
    Swal.fire({
      icon: "warning",
      text: "Are you sure to place this order?",
      showCancelButton: true,
      showConfirmButton: true,
    })
    .then((result) => {
      if(result.isConfirmed) {
        placeOrder()
      }
    })
  }

  const placeOrder  = () => {
    let requestPayload = {
      items: cart,
      orderTotal: totalPrice,
      payment_method: payload.payment_method
    }
    setSending(true)
    dispatch(createOrder(requestPayload))
    .then((response) => {
      if(response.payload?.status === true) {
        setOrderNumber(response.payload?.data?.order_number)
        Swal.fire({
          icon: 'success',
          text: 'Order placed successfully'
        })
        .then(() => setPrintReceipt(true))
      }
      else {
        Swal.fire({
          icon: 'error',
          text: response.payload?.message
        })
      }
    })
    .finally(() => setSending(false))
  }

  const handleAddToCart = (product) => {
    dispatch(addToCart(product));
  };

  const handleRemoveFromCart = (id) => {
    dispatch(removeFromCart(id));
  };

  const handleClear = () => {
    dispatch(clearCart());
    setPrintReceipt(false)
  };

  const isProductInCart = (id) => {
    return cart.some((item) => item.id === id);
  };

  const serverSideSearch = (event) => {
    const value = event.target.value;
    let requestPayload = payload
    payload.filter = value

    if (value.length >= 3) {
      setLoading(true);
      dispatch(getStoreProducts(payload))
      .then((response) => {
        const result = response.payload?.data?.data
        if(result != null && result !== undefined) {
            setProducts(result)
        }
        else {
          setProducts([])
        }
      })
      .finally(() => setLoading(false))
    }
    else if (value.length === 0) {
      loadProducts()
    }
  }

  const sortProducts = (order) => {
    setPayload((prevState) => ({...prevState, sortBy: order}))
    loadProducts()
  }

  const handleInput = (event) => {
    const {name, value} = event.target
    setPayload((prevState) => ({...prevState, [name]: value}))
}

  return (
    <Layout>
      <Container fluid className="mt-4">
        <InventoryConnectionButton />
        <Row>
          <Col md={8} className="product-grid no-print">
            <div className="product-search">
              <div className="input-group">
                  <button 
                      type="button" 
                      className="input-group-text"
                  >
                      <FontAwesomeIcon icon={"search"} style={{fontSize: "20px"}}/>
                  </button>
                  <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onKeyUp={serverSideSearch}
                  />
                    <div className="input-group-text d-flex px-1">
                      <button 
                        type="button" 
                        className={`btn-plain ${payload.sortBy === 'desc' ? "bg-primary text-white rounded" : ""} `}
                        onClick={() => sortProducts("desc")}
                      >
                        <FontAwesomeIcon icon={'arrow-up-short-wide'} />
                      </button>
                      <button 
                        type="button" 
                        className={`btn-plain ms-2 ${payload.sortBy === 'asc' ? "bg-primary text-white rounded" : ""} `}
                        onClick={() => sortProducts("asc")}
                      >
                        <FontAwesomeIcon icon={'arrow-down-short-wide'} />
                      </button>
                    </div>
              </div>
            </div>
            <p className="text-center mt-3 text-primary">
              { loading && <Spinner size="sm" /> }
            </p>
            <Row xs={1} md={2} lg={3} className="g-4">
              {products.map((product) => (
                <Col key={product.id}>
                  <Card 
                    className={`h-100 border-0 single-product`} 
                    onClick={() => !isProductInCart(product.id) ? handleAddToCart(product) : null}
                    style={{ overflow: 'hidden' }}
                  >
                    <div 
                      className="d-flex justify-content-center align-items-center" 
                      style={{
                        height: '200px', 
                        overflow: 'hidden',
                        backgroundColor: '#ffffff'
                      }}
                    >
                      <Card.Img 
                        variant="top" 
                        src={product.image} 
                        style={{
                          objectFit: 'contain', 
                          width: 'auto',
                          maxHeight: '100%',
                          maxWidth: '100%'
                        }}
                        className="mx-auto" 
                        onError={(e) => {
                          e.target.src = 'https://placehold.co/400x400'; 
                        }}
                      />
                    </div>
                    
                    <Card.Body className="d-flex flex-column">
                      <Card.Title className="text-center mb-2">{product.name}</Card.Title>
                      <Card.Text className="text-center text-primary fw-bold">
                        {formatCurrency(product.sales_price).with_currency}
                      </Card.Text>
                    </Card.Body>
                    
                    <Card.Footer className="bg-white border-0 pt-0">
                      <Button 
                        variant={isProductInCart(product.id) ? "success" : "primary"}
                        className="w-100"
                        onClick={(e) => {
                          e.stopPropagation();
                          isProductInCart(product.id) 
                            ? handleRemoveFromCart(product.id) 
                            : handleAddToCart(product);
                        }}
                      >
                        {isProductInCart(product.id) ? "Added to Cart" : "Add to Cart"}
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
              {
                products.length === 0 && <Col md={12} className="text-center mt-5">No items available</Col>
              }
          </Col>

          <Col md={4} sm={12} className="cart mt-5 storefront-cart">
              {/* <p>Session: ID: {sessionId}</p> */}
            <Cart 
              cartItems={cart}
              handleSubmit={checkout}
              requestStatus={sending}
              printReceipt={printReceipt}
              clearCart={handleClear}
              handleInput={handleInput}
              orderNumber={orderNumber}
            />
            <FloatingCart>
              <Cart 
                cartItems={cart}
                handleSubmit={checkout}
                requestStatus={sending}
                printReceipt={printReceipt}
                clearCart={handleClear}
                handleInput={handleInput}
                orderNumber={orderNumber}
              />
            </FloatingCart>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Storefront;