
import ServiceHelper from "./service.helper";


export default class ManagerService {

  static API_URL = '/members';
  
  static async signup(payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/user/", payload);
  };
  
  static async verification(payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/verify/", payload);
  };
  
  static async resendOtp(payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/re_verify/", payload);
  };
  
  static  async login (payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/login/", payload)
  };
  
  static  async forgotPassword (payload) {
    return await ServiceHelper.handlePostRequest(this.API_URL+"/forgot_password/", payload)
  };
  
  static  async resetPassowrd (payload) {
    return await ServiceHelper.handlePatchRequest(this.API_URL+"/validate_password/", payload)
  };
  
  static  async logout() {
      localStorage.removeItem("token")
    return await localStorage.removeItem("user");
  };

  static createBusiness(payload) {
    return ServiceHelper.handlePostRequest("/create_business/", payload, {headers:{'Content-Type': 'multipart/form-data'}});
  }

  static async getProfile() {
      return await ServiceHelper.handleGetRequest(this.API_URL+"/user");
  }

  static async getStore() {
      return await ServiceHelper.handleGetRequest(`${this.API_URL}/merchant_details/`);
  }

//   static createBusiness(payload) {
//       return ServiceHelper.handlePostRequest("/create_business/", payload, {headers:{'Content-Type': 'multipart/form-data'}});
//   }

  static updateBusinessInfo(user_id, payload) {
      return ServiceHelper.handlePostRequest(`${this.API_URL}/${user_id}/business`, payload)
  }

  static updateProfilePhoto(payload) {
      return ServiceHelper.handlePostRequest(`${this.API_URL}/profile_photo/`, payload)
  }

  static walletDetails() {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/wallet_details/`)
  }

  static createUser(payload) {
      return ServiceHelper.handlePostRequest(`${this.API_URL}/managers/`, payload)
  }

  static getUsers() {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/managers/`)
  }

  static getCustomers() {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/customers_list/`)
  }

  static getEmails() {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/customer_email/`)
  }

  static getSms() {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/customer_sms/`)
  }

  static createSms(payload) {
      return ServiceHelper.handlePostRequest(`${this.API_URL}/customer_sms/`, payload)
  }

  static createEmail(payload) {
      return ServiceHelper.handlePostRequest(`${this.API_URL}/customer_sms/`, payload)
  }

  static deleteSms(id) {
      return ServiceHelper.handleDeleteRequest(`${this.API_URL}/delete_sms/${id}/`)
  }

  static deleteEmail(id) {
      return ServiceHelper.handleDeleteRequest(`${this.API_URL}/delete_email/${id}/`)
  }

  static deleteCustomer(customer_id) {
      return ServiceHelper.handleDeleteRequest(`${this.API_URL}/delete_customer/${customer_id}/`)
  }

  static deleteUser(user_id) {
      return ServiceHelper.handleDeleteRequest(`${this.API_URL}/manage_team/${user_id}/`)
  }

  static saveSocialMedia(payload) {
      return ServiceHelper.handlePatchRequest(`${this.API_URL}/social_media/`, payload)
  }

  static getSocialMedia() {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/social_media/`)
  }

  static manageUser(payload) {
      return ServiceHelper.handlePatchRequest(`${this.API_URL}/manage_team/${payload.user_id}/`, payload)
  }

  static withdrawal(payload) {
      return ServiceHelper.handlePostRequest(`${this.API_URL}/withdrawal/`, payload)
  }

  static depositFund(payload) {
      return ServiceHelper.handlePostRequest(`${this.API_URL}/funds_deposit/`, payload)
  }

  static pinVerification(payload) {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/verify_pin/?wallet_pin=${payload.wallet_pin}`)
  }

  static changePassword(payload) {
      return ServiceHelper.handlePostRequest(`${this.API_URL}/change_password/`, payload)
  }

  static accountUpgrade(payload) {
      return ServiceHelper.handlePostRequest(`${this.API_URL}/account_upgrade/`, payload)
  }

  static rewards() {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/user_referrals/`)
  }

  static customerRating(payload) {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/customer_rating/${payload.merchant_id}`)
  }

  static getNotifications(option) {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/notification/?read=${option}`)
  }

  static transactionHistory() {
      return ServiceHelper.handleGetRequest(`${this.API_URL}/transactions/`)
    //   return ServiceHelper.handleGetRequest(`${this.API_URL}/transactions/?from_date=2023-10-30&to_date=2023-10-31&status=pending&transaction_type=Withdrawal`)
  }

  static claimPaycode(payload){
    return ServiceHelper.handlePostRequest(`${this.API_URL}/voucher/`, payload);
  }

  static getPaycodes(payload){
    return ServiceHelper.handleGetRequest(`${this.API_URL}/voucher/`, payload);
  }

  static readNotifications(id) {
    return ServiceHelper.handlePatchRequest(`${this.API_URL}/read_notification/${id}/`, {read: true})
  }

  static getVirtualAccounts() {
    return ServiceHelper.handleGetRequest(`${this.API_URL}/virtual_accounts/`)
  }

  static createLoyaltyVoucher(payload){
    return ServiceHelper.handlePostRequest(`${this.API_URL}/loyalty_voucher/`, payload);
  }

  static getTaxCalculators(params) {
      let uri =  `/tax`;
      if(params.filter_year !== '' && params.filter_year !== undefined && params.filter_month !== '' && params.filter_month !== undefined) {
          uri = `/tax/?filter_year=${params.filter_year}&filter_month=${params.filter_month}`;
      }
      return ServiceHelper.handleGetRequest(`${this.API_URL}${uri}`);
  }

}
