import { Row, Col, Container, Card, Button, Table, Badge, Dropdown, Form, Spinner, Modal } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Paginator from "../../components/table/Paginator";
import { useState, useEffect } from "react";
import link from '../../assets/images/link2.svg';
import DrewsModal from "../../components/modal/DrewsModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices, addInvoice, invoiceVerification } from "../../redux/slices/account.service";
import { clearMessage } from "../../redux/slices/message";
import formatCurrency from "../../utils/currencyFormmter";
import Swal from "sweetalert2";
import dateFormatter from "../../utils/dateFormatter";


const Invoices = () => {

    const dispatch = useDispatch()
    const stopPropagation = (e) => e.stopPropagation();


    const [modalShow, setModalShow] = useState(false)
    const [showForm, setForm] = useState(false)
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({link: window.location.href+'/payment/'})
    const [invoice, setInvoice] = useState({})
    const [invoices, setInvoices] = useState([])
    const [errors, setErrors] = useState({})
    const [analytics, setAnalytics] = useState({})
    const [showInvoice, setShowInvoice] = useState(false)
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [page, setPage] = useState(1)


    // const [alertMessage, setAlertMessage] = useState(null)

    const { message } = useSelector((state) => state.message)
    const { business, user } = useSelector((state) => state.user)

    useEffect(() => {
        loadInvoice(page)
    }, [page])

    useEffect(() => {
        if (message?.status === 'True') {
            delete errors['error_message']
            setErrors(errors)
            setShow(true)
            setForm(false)
            Swal.fire({
                icon: 'success',
                text: message.message
            })
        }
        else if(message?.status === 'False') {
            Swal.fire({
                icon: 'error',
                text: message.message
            })
        }
        dispatch(clearMessage())
    }, [message, dispatch])

    const selectInvoice = (item) => {
        setInvoice(item)
        setShowInvoice(true)
    }

    const handleInput = (event) => {
        const { name, value } = event.target

        const regex = /^\d*\.?\d*$/;

        
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
            setPayload({ ...payload, [name]: value });
        }
        
    }

    const createNewInvoice = () => {
        if(
            !payload.amount && !payload.customer_name && 
            !payload.description && !payload.phone
        ) { return }

        setLoading(true)
        dispatch(addInvoice(payload))
        .then((response) => {
            loadInvoice()
        })
        .finally(() => setLoading(false))
    }

    const loadInvoice = (pageNumber) => {
        setLoading(true)
        dispatch(fetchInvoices(pageNumber))
        .then((response) => {
            if(response.payload?.results) {
                setInvoices(response.payload.results)
            }
            if(response.payload?.analytics) {
                setAnalytics(response.payload.analytics)
            }
            if(response.payload?.next || response.payload?.previous) {
                const data = {
                    next: response.payload?.next,
                    prev: response.payload?.previous,
                }
                setpaginationData(data)
            }    
        })
        .finally(() => setLoading(false))
    }

    const verifyInvoicePayment = (ref) => {
        Swal.fire({
            icon: 'warning',
            text: 'This action will settle this invoice as paid. Do you wish to continue?',
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: `Yes, proceed`,
            cancelButtonText: 'Cancel'
        })
        .then((result) => {
            if (result.isConfirmed) {
                setLoading(true)
                dispatch(invoiceVerification({ref: ref}))
                .then((response) => {
                    if(response.payload?.success === true) {
                        Swal.fire({
                            icon: "success",
                            text: response.payload.message,
                        })
                        loadInvoice()
                    }
                    if(response.payload?.success === 'False') {
                        Swal.fire({
                            icon: "error",
                            text: response.payload.message,
                        })
                    }
                })
                .finally(() => setLoading(false))
            }

        })
    }

    const handlePagination = (data) => {
        setPage(data)
        loadInvoice(data)
    }


    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <Col md={12} className="d-flex align-items-center justify-content-between no-print">
                    <h3>Invoice</h3>
                    <Button 
                        type="button" 
                        variant="primary" 
                        size="lg"
                        onClick={ () => setForm(true)}
                    >
                        <FontAwesomeIcon icon="fa fa-receipt" style={{ fontSize: 20 }} />
                        <span className="ps-2">Create Invoice</span>
                    </Button>
                </Col>

                <Col md={12} className="mt-4">
                    {
                        loading ? (
                            <div className="page-loader">
                                <Spinner size="lg" variant="primary"/>
                            </div>
                        ) : null
                    }
                    <Row className="data-card mb-4 no-print">
                        <Col md={4} sm={12} lg={3} xs={12} className="mb-2">
                            <Card className="border-0 shadow-sm bg--light">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#4F4F4F"/>
                                            <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#4F4F4F"/>
                                            <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#4F4F4F"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#4F4F4F"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.all_invoices || '0'}</h5>
                                        <p className="m-0">All Invoice created</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} sm={12} lg={3} xs={12} className="mb-2">
                            <Card className="border-0 shadow-sm bg--success">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#008000"/>
                                            <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#008000"/>
                                            <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#008000"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#008000"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.all_paid_invoices || '0'}</h5>
                                        <p className="m-0">All invoice paid</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} sm={12} lg={3} xs={12} className="mb-2">
                            <Card className="border-0 shadow-sm bg--warning">
                                <Card.Body className="d-flex align-items-center gap-3 p-3">
                                    <div className="icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                            <path d="M11.25 8.62516C11.25 8.27999 10.9702 8.00016 10.625 8.00016H5.625C5.27982 8.00016 5 8.27999 5 8.62516C5 8.97034 5.27982 9.25016 5.625 9.25016H10.625C10.9702 9.25016 11.25 8.97034 11.25 8.62516Z" fill="#F2994A"/>
                                            <path d="M10.4167 11.1252C10.4167 10.78 10.1368 10.5002 9.79167 10.5002H5.625C5.27982 10.5002 5 10.78 5 11.1252C5 11.4703 5.27982 11.7502 5.625 11.7502H9.79167C10.1368 11.7502 10.4167 11.4703 10.4167 11.1252Z" fill="#F2994A"/>
                                            <path d="M10.625 13.0002C10.9702 13.0002 11.25 13.28 11.25 13.6252C11.25 13.9703 10.9702 14.2502 10.625 14.2502H5.625C5.27982 14.2502 5 13.9703 5 13.6252C5 13.28 5.27982 13.0002 5.625 13.0002H10.625Z" fill="#F2994A"/>
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5 18.6252H15.8333C17.099 18.6252 18.125 17.5992 18.125 16.3335V11.7502C18.125 11.405 17.8452 11.1252 17.5 11.1252H14.7917V4.6195C14.7917 3.43333 13.4509 2.74335 12.4857 3.4328L12.3398 3.537C11.6894 4.00157 10.8084 4.00001 10.1549 3.53325C9.06839 2.75714 7.59828 2.75714 6.51173 3.53325C5.85826 4.00001 4.97725 4.00157 4.32685 3.537L4.18097 3.4328C3.21575 2.74335 1.875 3.43333 1.875 4.6195V15.5002C1.875 17.2261 3.27411 18.6252 5 18.6252ZM7.23827 4.55042C7.89021 4.08476 8.77646 4.08476 9.42839 4.55042C10.5134 5.32544 11.9783 5.33136 13.0664 4.55416L13.2122 4.44997C13.3501 4.35148 13.5417 4.45004 13.5417 4.6195V16.3335C13.5417 16.7086 13.6318 17.0626 13.7915 17.3752H5C3.96447 17.3752 3.125 16.5357 3.125 15.5002V4.6195C3.125 4.45004 3.31654 4.35148 3.45442 4.44997L3.6003 4.55416C4.68838 5.33136 6.15325 5.32544 7.23827 4.55042ZM14.7917 16.3335V12.3752H16.875V16.3335C16.875 16.9088 16.4086 17.3752 15.8333 17.3752C15.258 17.3752 14.7917 16.9088 14.7917 16.3335Z" fill="#F2994A"/>
                                        </svg>
                                    </div>
                                    <div>
                                        <h5 className="m-0">{analytics?.all_unpaid_invoices || '0'}</h5>
                                        <p className="m-0">All Invoice unpaid</p>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Form.Group as={Col} md={5} className="mb-2">
                            <Form.Control
                                type="search"
                                placeholder="Search by ID"
                            />
                        </Form.Group>
                        <Form.Group as={Col} md={2} className="mb-2">
                            <div className="filter-button gap-1">
                                <span>Filter by:</span>
                                <Form.Select
                                    required
                                    name="sort"
                                >
                                    <option value="">None</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md={2} className="mb-2">
                            <div className="filter-button gap-1">
                                <span>Sort by:</span>
                                <Form.Select
                                    required
                                    name="sort"
                                >
                                    <option value="">None</option>
                                </Form.Select>
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md={3} className="mb-2">
                            <Button type="button" variant="light" className="bg-white text-primary">
                                <FontAwesomeIcon icon="fa fa-share-nodes" style={{fontSize: '17px'}} />
                                <span className="px-2">Export sheet</span>
                            </Button>
                        </Form.Group>
                    </Row>

                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive">
                            <Table size="sm" className="table-color">
                                <thead>
                                    <tr>
                                        <th>Reference ID</th>
                                        <th>Customer</th>
                                        <th>Description</th>
                                        <th>Amount</th>
                                        <th>Date Created</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        invoices?.length ? (
                                            invoices?.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        {item.ref}
                                                    </td>
                                                    <td>
                                                        {item.name}
                                                    </td>
                                                    <td>
                                                        {item.note}
                                                    </td>
                                                    <td>
                                                        {formatCurrency(item.amount).with_currency}
                                                    </td>
                                                    <td>
                                                        {dateFormatter(item.date)}
                                                    </td>
                                                    <td>
                                                        <p className="d-flex m-0 align-items-center justify-content-between">
                                                            <Badge 
                                                                className={!item.used ? 'badge-pending' :  
                                                                (item.used ? 'badge-active' : 'badge-used')}
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                    <circle cx="5" cy="5" r="5" fill={
                                                                        !item.used ? "#828282" :  
                                                                        (item.used ? "#008000" : "#FAB941")
                                                                    }
                                                                    />
                                                                </svg>
                                                                <span className="ps-1">
                                                                    {!item.used ? 'Unpaid' : 'Paid'}
                                                                </span>
                                                            </Badge> 
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="light" className="border-0 bg-white" size="sm" id="dropdown-basic">
                                                                    <FontAwesomeIcon icon="ellipsis-vertical" style={{fontSize: "20px"}} className="text-muted"/>
                                                                </Dropdown.Toggle>
    
                                                                <Dropdown.Menu className="border-0 shadow px-3 py-4">
                                                                    <Dropdown.Item 
                                                                        eventKey={1} 
                                                                        className="text-primary d-flex align-items-center gap-1" 
                                                                        onClick={() => selectInvoice(item)}
                                                                    >
                                                                    <i className="lni lni-eye" style={{fontSize: "20px"}}></i>  View Details
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Divider />
                                                                    <Dropdown.Item 
                                                                        eventKey={2} 
                                                                        className="d-flex align-items-center gap-1"
                                                                        href={item.link}
                                                                        target="_blank"
                                                                        // onClick={ () => selectTransaction(item.id) }
                                                                    >
                                                                        <FontAwesomeIcon icon="folder-open" /> Pay Invoice
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </p>
                                                    </td>
                                                </tr>
                                            ))
                                        ) 
                                        :
                                        (
                                            <tr>
                                                <td colSpan={5} className="text-center text-muted">No data found</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                    {
                        invoices?.length ? (
                            <div className="table-responsive mt-5">
                                <Paginator
                                    next={paginationData.next}
                                    prev={paginationData.prev}
                                    setCurrentPage={ handlePagination }
                                />
                            </div>
                        ) : null
                    }
                </Col>


                <Modal 
                    show={showInvoice} 
                    fullscreen={true} 
                    onHide={() => setShowInvoice(false)}
                    dialogClassName="half-screen-modal"
                    backdrop="static"
                    onClick={stopPropagation}
                >
                    <Modal.Header className="no-print">
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <button className="btn-plain">
                                <FontAwesomeIcon icon="chevron-left" onClick={ () => setShowInvoice(false) }/> Invoice
                            </button>
                            <div>
                                {
                                    !invoice?.used ? (
                                        <Button type="button"b variant="primary" className="border-0 px-4" onClick={() => verifyInvoicePayment(invoice.ref)}>
                                        <FontAwesomeIcon icon="check-to-slot" style={{fontSize: "20px" }} />  Settle Invoice <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                        </Button>
                                    ) : null
                                }
                                <Button type="button" className="bg---primary border-0 px-4 ms-2" onClick={() => window.print()}>
                                    <FontAwesomeIcon icon="print" />  Print
                                </Button>
                                <Button type="button" variant="primary" className="border-0 ms-2">
                                    <FontAwesomeIcon icon="paper-plane"/>  Share Invoice
                                </Button>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body className="p-4">
                        <div className="invoice px-3 py-5 border border-1 printarea">
                            <div className="profile-avatar-plain">
                                <img src={invoice?.merchant_data?.profile_image} />
                            </div>
                            <div className="border-bottom border-bottom-1 d-flex align-items-center justify-content-between pb-2 py-2">
                                <h5 className="mb-0">Invoice</h5>
                                <p className="text-muted text-sm m-0">Issue Date: {dateFormatter(invoice?.date)}</p>
                            </div>
                            <div className="mt-3 mb-3 receipt-anchor">
                                <ul>
                                    <li className="text-muted">
                                        <span className="title">Sender:</span>
                                        <strong>{business?.store_name}</strong>
                                    </li>
                                    <li>Address: {business?.store_address}</li>
                                    <li>Tel no: {user?.mobile}</li>
                                    <li>E-mail: {user?.email}</li>
                                </ul>
                                <ul className="text-right">
                                    <li className="text-muted">
                                        <span className="title">Invoice To:</span>
                                        <strong>{invoice?.name}</strong>
                                    </li>
                                    <li>Tel no: {invoice?.phone}</li>
                                    <li>E-mail: {invoice?.email}</li>
                                </ul>
                            </div>
                            <div className="table-responsive mb-3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" className="text-center">Description</th>
                                            <th scope="col" className="text-center">Status</th>
                                            <th scope="col" className="text-center">Amount (₦)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="text-sm text-muted w-50 border-0">
                                                {invoice?.note}
                                            </td>
                                            <td className={invoice.used ? "text-success text-center border-0" : "text-danger text-center border-0"}>
                                                {invoice?.used ? 'Paid' : 'Not Paid'}
                                            </td>
                                            <td className="text-center border-0">
                                                {formatCurrency(invoice.amount).with_currency}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="payment-link-bg bg-light wrap">
                                <h6 className="text-sm">PAYMENT LINK</h6>
                                <a href={invoice?.link} target="_blank">
                                    <img src={link} alt="link" className="me-2" />   {invoice?.link}
                                </a>
                            </div>
                            <div className="text-center mt-5">
                                <p>Thank you for your patronage</p>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <DrewsModal
                    show={showForm}
                    onHide={() => setForm(false)}
                    size="md"
                    dismissable={true}
                    title="Create Invoice"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Amount"
                                    name="amount"
                                    inputMode="numeric"
                                    onChange={handleInput}
                                    value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                />
                                {
                                    errors['amount'] &&
                                    <Form.Text className="text-danger">{errors['amount']}</Form.Text> 
                                }
                            </Form.Group>

                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Customer name</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Customer name"
                                    name="customer_name"
                                    onChange={handleInput}
                                />
                                {
                                    errors['customer_name'] ? 
                                    <Form.Text className="text-danger">{errors['customer_name']}</Form.Text> : null
                                }
                            </Form.Group>

                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Customer Phone number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    required
                                    placeholder="Phone number"
                                    name="phone"
                                    inputMode="tel"
                                    onChange={handleInput}
                                />
                                {
                                    errors['phone'] ? 
                                    <Form.Text className="text-danger">{errors['phone']}</Form.Text> : null
                                }
                            </Form.Group>

                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Customer Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    required
                                    placeholder="Email"
                                    name="email"
                                    inputMode="email"
                                    onChange={handleInput}
                                />
                                {
                                    errors['email'] ? 
                                    <Form.Text className="text-danger">{errors['email']}</Form.Text> : null
                                }
                            </Form.Group>

                            <Form.Group as={Col} md={12} className="mb-3">
                                <Form.Label className="mb-0">Description</Form.Label>
                                <textarea 
                                    name="description" 
                                    className="form-control" 
                                    placeholder="Description"
                                    onChange={handleInput}
                                    required
                                ></textarea>
                                {
                                    errors['description'] ? 
                                    <Form.Text className="text-danger">{errors['description']}</Form.Text> : null
                                }
                            </Form.Group>

                            {
                                errors['error_message'] &&
                                <Form.Text className="text-danger">{errors['error_message']}</Form.Text> 
                            }

                            <Form.Group 
                                as={Col} 
                                md={12} 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={createNewInvoice}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Create Invoice <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>
            </Container>
        </Layout>
    );
}

export default Invoices;