import { Col, Container, Card, Table, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSuppliers } from "../../redux/slices/inventory.slice";
import InventoryConnectionButton from "../../components/InventoryConnector";

const VendorList = () => {

    const dispatch = useDispatch();

    const initialState = {
        loading: false,
        show_vendor_form: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            to_currency: 'NGN', 
            currency: 'USD', 
            amount: 1,
            page: 1,
            from_date: '',
            to_date: '',
            status: '',
            download: '',
            filter_by: '',
            sort_by: '',
            filter: '',
            gateway: 'FasePay',
        },
        errors: {},
        suppliers: [],
        products: [],
        restockMode: false,
        product: {},
        pricelist: [],
        vendors: []
    }

    const [state, setState] = useState(initialState)
    const [loading, setLoading] = useState(false)

    const { invLogin } = useSelector((state) => state.inventory)
    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)


    useEffect(() => {
        loadPrices()
    }, [])

    const handleInput = (event) => {
        const {name, value} = event.target
         if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: newValue}}))
        }
        else {
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
        }
    }

    const loadPrices = () => {
        setLoading(true)
        dispatch(getSuppliers())
        .then((response) => {
            const result = response.payload?.data?.data
            if(result) {
                setState((prevState) => ({...prevState, vendors:  result})) 
            }
        })
        .finally(() => setLoading(false))
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <InventoryConnectionButton />
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd">
                    <div className="col-md-4">
                        <h3 className="m-0">List of suppliers</h3>
                    </div>
                </Col>

                <div className="mt-4">

                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>SN</th>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                        </tr>
                                    </thead>
                                    <tbody className="data-container">
                                        {
                                            loading ? (
                                                <div className="in-page-loader">
                                                    <Spinner size="lg" variant="primary"/>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            state.vendors?.length ? (
                                                state.vendors?.map((item, index) => (
                                                    <tr key={index}> 
                                                        <td>
                                                            {index+1}
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td>{item.email} </td>
                                                        <td>{item.phone_number} </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
}

export default VendorList;