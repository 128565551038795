// src/store/cartSlice.js
import { createSlice } from "@reduxjs/toolkit";

// Load cart from sessionStorage for a given session ID
const loadCartFromSessionStorage = (sessionId) => {
  try {
    const cart = sessionStorage.getItem(`cart_${sessionId}`);
    return cart ? JSON.parse(cart) : [];
  } catch (error) {
    console.error("Failed to load cart from sessionStorage:", error);
    return [];
  }
};

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [], // Start empty, load dynamically
    sessionId: null, // Start null, set dynamically
  },
  reducers: {
    addToCart: (state, action) => {
      const existingItem = state.items.find(
        (item) => item.id === action.payload.id
      );
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.items.push({ ...action.payload, quantity: 1 });
      }
      sessionStorage.setItem(`cart_${state.sessionId}`, JSON.stringify(state.items));
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload);
      sessionStorage.setItem(`cart_${state.sessionId}`, JSON.stringify(state.items));
    },
    updateQuantity: (state, action) => {
      const { id, quantity } = action.payload;
      const item = state.items.find((item) => item.id === id);
      if (item) {
        if (quantity <= 0) {
          state.items = state.items.filter((item) => item.id !== id);
        } else {
          item.quantity = quantity;
        }
      }
      sessionStorage.setItem(`cart_${state.sessionId}`, JSON.stringify(state.items));
    },
    clearCart: (state) => {
      state.items = [];
      sessionStorage.removeItem(`cart_${state.sessionId}`);
    },
    setSessionCart: (state, action) => {
      state.sessionId = action.payload.sessionId;
      state.items = action.payload.items;
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  updateQuantity,
  clearCart,
  setSessionCart,
} = cartSlice.actions;

export default cartSlice.reducer;

export const initializeCartSession = () => (dispatch) => {
  const generateSessionId = () => Math.random().toString(36).substring(7);
  const existingSessionId = sessionStorage.getItem("cart_session_id");
  const sessionId = existingSessionId || generateSessionId();
  
  if (!existingSessionId) {
    sessionStorage.setItem("cart_session_id", sessionId);
  }

  const items = loadCartFromSessionStorage(sessionId);
  dispatch(setSessionCart({ sessionId, items }));
};