import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import InventoryService from "../../services/inventory.service";


export const loginCustomer = createAsyncThunk(
    "/admin/sign-in",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.login(payload);
        thunkAPI.dispatch(inventorySlice.actions.setToken(response))
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const createAdmin = createAsyncThunk(
    "/admin/create-admin",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.createAdmin(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const register = createAsyncThunk(
    "/admin/register",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.signup(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const addProduct = createAsyncThunk(
    "/admin/create-product",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.addStock(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const updateProduct = createAsyncThunk(
    "/admin/uproduct/:id",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.updateStock(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getProduct = createAsyncThunk(
    "/admin/product/:id",
    async(product_id, thunkAPI) => {
      try {
        const response = await InventoryService.getStockById(product_id);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const removeProduct = createAsyncThunk(
    "/admin/product/:id",
    async(product_id, thunkAPI) => {
      try {
        const response = await InventoryService.deleteStock(product_id);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const productUpload = createAsyncThunk(
    "/admin/product/import",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.importStock(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const productImagesUpload = createAsyncThunk(
    "/admin/product/import",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.uploadProductImage(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getProductPrices = createAsyncThunk(
    "/admin/pricelist/all",
    async(_, thunkAPI) => {
      try {
        const response = await InventoryService.getPricelist();
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const searchProduct = createAsyncThunk(
    "/admin/products/search",
    async(param, thunkAPI) => {
      try {
        const response = await InventoryService.searchProducts(param);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getProducts = createAsyncThunk(
    "/admin/all-products",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.getAllStock(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const productFilter = createAsyncThunk(
    "/admin/filter-products",
    async(_, thunkAPI) => {
      try {
        const response = await InventoryService.filterStock();
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getAnalytics = createAsyncThunk(
    "/admin/analytics",
    async(_, thunkAPI) => {
      try {
        const response = await InventoryService.stockAnalytics();
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const restock = createAsyncThunk(
    "/admin/create-admin",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.restockRequest(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getStockRequests = createAsyncThunk(
    "/admin/restocks",
    async(_, thunkAPI) => {
      try {
        const response = await InventoryService.stockRequests();
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const restockManagement = createAsyncThunk(
    "/admin/restock-manage",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.manageStockRequest(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

// VENDOR aka supplier
export const createSupplier = createAsyncThunk(
    "/admin/create-vendor",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.createVendor(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getSuppliers = createAsyncThunk(
    "/admin/all-vendor",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.getVendors(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const setupInventory = createAsyncThunk(
    "/wallx-onboarding",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.wallxOnaboading(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const createSales = createAsyncThunk(
    "/sales",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.productSale(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const salesAnalytics = createAsyncThunk(
    "/sales/analytics",
    async(_, thunkAPI) => {
      try {
        const response = await InventoryService.getSalesAnalytics();
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const salesAnalyticsChart = createAsyncThunk(
    "/sales/analytics/chart",
    async(_, thunkAPI) => {
      try {
        const response = await InventoryService.getSalesChart();
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const salesAnalyticsChartDownload = createAsyncThunk(
    "/sales/analytics/chart/download",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.downloadSalesChart(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)

export const getAllSales = createAsyncThunk(
    "/sales/list",
    async(payload, thunkAPI) => {
      try {
        const response = await InventoryService.allSales(payload);
        return response;
      } catch (error) {
        return error;
      }
    }
)


// ************************************************************************************************
// ************************************************************************************************
//                      STOREFRONT
// ************************************************************************************************

export const getStoreProducts = createAsyncThunk(
  "/storefront",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.getStorefrontProducts(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const getAllOrders = createAsyncThunk(
  "/storefront/orders",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.getOrders(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const createOrder = createAsyncThunk(
  "/storefront/create-order",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.submitOrder(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const getOrderById = createAsyncThunk(
  "/storefront/order/:id",
  async(id, thunkAPI) => {
    try {
      const response = await InventoryService.getOrder(id);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const orderUpdate = createAsyncThunk(
  "/storefront/order/:id",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.updateOrder(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const manageOrder = createAsyncThunk(
  "/storefront/manager-order",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.changeOrderStatus(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const removeOrder = createAsyncThunk(
  "/storefront/order/:id",
  async(id, thunkAPI) => {
    try {
      const response = await InventoryService.deleteOrder(id);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const uploadProduct = createAsyncThunk(
  "/storefront/product-upload",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.productUpload(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const deleteProduct = createAsyncThunk(
  "/products/:id",
  async(id, thunkAPI) => {
    try {
      const response = await InventoryService.deleteStock(id);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const getOrgVAT = createAsyncThunk(
  "/storefront/get-vat",
  async(_, thunkAPI) => {
    try {
      const response = await InventoryService.getBrandVAT();
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const vatManagement = createAsyncThunk(
  "/storefront/vat-management",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.manageVAT(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const createProduct = createAsyncThunk(
  "/storefront/product/create",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.createStorefrontProduct(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const importProduct = createAsyncThunk(
  "/storefront/product/import",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.importStorefrontProduct(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const getOrderAnalytics = createAsyncThunk(
  "/storefront/order/analytics",
  async(_, thunkAPI) => {
    try {
      const response = await InventoryService.orderAnalytics();
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const updateStorefrontItem = createAsyncThunk(
  "products/updateStorefrontItem",
  async ({ id, payload }, thunkAPI) => {
    try {
  
      console.log("Updating product ID:", id);
      console.log("Payload data:", payload);

      const response = await InventoryService.updateStorefrontProduct(payload, id);
  
      return response;
    } catch (error) {
      console.error("Update product error:", error);
      return error
      // Return consistent error structure
      // return thunkAPI.rejectWithValue({
      //   message: error.response?.data?.message || error.message || 'Update failed',
      //   status: error.response?.status,
      //   errors: error.response?.data?.errors
      // });
    }
  }
);

export const connectToInventory = createAsyncThunk(
  "/wallx-onboarding",
  async(payload, thunkAPI) => {
    try {
      const response = await InventoryService.wallxOnaboading(payload);
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const disconnectFromInventory = createAsyncThunk(
  "/inventory/logout",
  async(_, thunkAPI) => {
    try {
      const response = await InventoryService.logOutServer();
      return response;
    } catch (error) {
      return error;
    }
  }
)

export const logout = createAsyncThunk(
  "inventory/logout", 
  async (_, thunkAPI) => {
    thunkAPI.dispatch(inventorySlice.actions.signOut())
    return true
});

const inventorySlice = createSlice({
    name: "inventory",
    initialState: {
      invLogin: false,
      products: null,
      loading: false,
    },
    reducers: {
      startLoading(state) {
        state.loading = true
      },
      setUser(state, action) {
        state.loading = false
        state.user = action.payload
      },
      setToken(state, action) {
        localStorage.setItem("invhash", action.payload.data?.access_token??''); 
        state.invLogin = action.payload.data?.access_token ? true : false
      },
      signOut(state) {
        state.invLogin = false
        state.invhash = null
        localStorage.removeItem("invhash"); 
      },
    },
    extraReducers: {
      [getProducts.fulfilled]: (state, action) => {
        state.products = action.payload.data
      },
      [getAnalytics.fulfilled]: (state, action) => {
        state.analytics = action.payload.data
      },
      [disconnectFromInventory.fulfilled]: (state, action) => {
        state.invLogin = false;
        localStorage.removeItem('redux-inventory')
        localStorage.removeItem('invhash')
      },
    }
  })
  
  const { reducer } = inventorySlice 
  export default reducer;