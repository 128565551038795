import { Container, Card} from "react-bootstrap";
import Layout from "../components/Layout";

const Accounting = () => {
 
    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <div className="mt-4">
                    <Card className="border-0 shadow-sm">
                        <Card.Body className="table-responsive">
                            <div className="text-center">
                                <p>
                                  <strong>Coming Soon </strong>
                                </p>
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
}

export default Accounting;