import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Button, Table, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShop, faLineChart, faCloudDownload, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Bar } from 'react-chartjs-2';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import Swal from 'sweetalert2';
import { salesAnalytics, salesAnalyticsChart, getAllSales, salesAnalyticsChartDownload } from '../../redux/slices/inventory.slice'; 
import InventoryConnectionButton from '../../components/InventoryConnector';
import Layout from '../../components/Layout';
import dateFormatter from '../../utils/dateFormatter';
import formatCurrency from '../../utils/currencyFormmter';
import Paginator from '../../components/table/Paginator';
import RecordFilter from '../../components/RecordFilter';

const SalesTracker = () => {

    const isLoaded = useRef(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.auth.user);
    const { invLogin = false } = useSelector((state) => state.inventory);

    const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const initialState = {
        payload: { amount: null },
        loading: false,
        errors: {},
        analytics: [],
        inflowData: [],
        outflowData: [],
        labels: labels,
        system_info: '',
        profile: {}
    }


    const [showModal, setShowModal] = useState(false)
    const [payload, setPayload] = useState({
        to_currency: 'NGN', 
        currency: 'USD', 
        amount: 1,
        page: 1,
        from_date: '',
        to_date: '',
        status: '',
        download: '',
        filter_by: '',
        sort_by: '',
        filter: '',
        gateway: 'FasePay',
    })
    const [loading, setLoading] = useState(false)
    const [chartData, setChartData] = useState([])
    const [paginationData, setpaginationData] = useState({next: '', prev: ''})
    const [analytics, setAnalytics] = useState({})
    const [results, setResults] = useState({})
    const [state, setState] = useState(initialState)
    const [viewFigure, setViewFigure] = useState(false)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: true
            },
        },
        scales: {
            x: {
                grid: { display: false }
            },
            y: {
                grid: { display: true }
            },
        }
    };
        
    const data = {
        labels: state.labels,
        datasets: chartData
    }

    useEffect(() => {
        if(!isLoaded.current) {
            loadAnalytics()
            loadSales()
            loadChartAnalytics()
            isLoaded.current = true 
        }
    }, [payload.page])

    useEffect(() => {
        if(invLogin === true) {
            loadAnalytics()
            loadSales()
            loadChartAnalytics()
        }
    }, [invLogin])


    const handleInput = (event) => {
        const {name, value} = event.target
        if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^\d.]/g, ''))
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState) => ({...prevState, [name]: value}))
        }
    }

    const handleSelection = (item) => {
        setShowModal(true)
    }
    
    const handlePagination = (data) => {
        setPayload({...payload, page: data})
    }

    const loadAnalytics = () =>  {
        setLoading(true)
        dispatch(salesAnalytics(payload))
        .then((response) => {
            if(response.payload?.data) {
                setAnalytics(response.payload.data)
            }

        })
        .finally(() => setLoading(false))
    }

    const loadChartAnalytics = () =>  {
        setLoading(true)
        dispatch(salesAnalyticsChart())
        .then((response) => {
            if(response.payload?.datasets) {
                setChartData(response.payload.datasets)
            }
        })
        .finally(() => setLoading(false))
    }

    const loadSales = () =>  {
        setLoading(true)
        dispatch(getAllSales(payload))
        .then((response) => {
            if(response.payload?.data?.data) {
                setResults(response.payload.data.data)
            }

        })
        .finally(() => setLoading(false))
    }

    const downloadChart = () => {
        dispatch(salesAnalyticsChartDownload(payload))
        .then((response) => {
            console.log("RESPONSE", response)
            if(response.type === "/sales/analytics/chart/download/fulfilled") {
                Swal.fire({
                    icon: 'success',
                    title: 'Download started',
                    text: 'Your report is being downloaded',
                })
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Download failed',
                    text: 'An error occurred while downloading your report',
                })
            }
        })
    }

    const generatePdf = () => {
        const input = document.getElementById('pdf-content');
        
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            
            const imgWidth = 190; // A4 width in mm
            const pageHeight = pdf.internal.pageSize.height;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;

            let position = 0;

            // Add the image to the PDF
            pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }
            
            // Save the PDF
            pdf.save(`Sales-report.pdf`);
        });
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <InventoryConnectionButton />
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd">
                    <div className="col-md-4">
                        <p>
                            <h3 className="m-0">Sales Tracker</h3>
                            <small>Track daily sales</small>
                        </p>                        
                    </div>
                    <div className="col-md-8 text-right">
                        <Button 
                            type="button" 
                            className="border-0 mb-2 px-3"
                            onClick={ () => navigate(user?.user_type === 'owner' ? '/inventory/pricelist' : '/members/inventory/pricelist')}
                        >
                            <FontAwesomeIcon icon={'shop'}/>
                            <span className="ps-2">Sell</span>
                        </Button>
                        <Button 
                            type="button" 
                            className="border-0 mb-2 px-3 ms-2"
                            onClick={() => navigate(user?.user_type === 'owner' ? '/inventory' : '/members/inventory')}
                        >
                            <FontAwesomeIcon icon={'shop'}/>
                            <span className="ps-2">Inventory</span>
                        </Button>
                        <Button 
                            type="button" 
                            variant="secondary" 
                            className="border-0 mb-2 ms-2 text-white"
                            onClick={ () => navigate(user?.user_type === 'owner' ? '/inventory/sell' : '/members/inventory/sell')}
                        >
                            <FontAwesomeIcon icon={'line-chart'} />
                            <span className="ps-2">Sell Product</span>
                        </Button>
                    </div>
                </Col>

                <div className="mt-4">
                    <Row className="data-card mb-4">
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total Sales Today</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">
                                            {viewFigure ? formatCurrency(analytics.daily_revenue).with_currency : '********'} 
                                            <button type="button" className="btn-plain" onClick={() => setViewFigure(!viewFigure)}> { viewFigure ? <FontAwesomeIcon icon={"eye-slash"} style={{fontSize: "18px"}} /> : <FontAwesomeIcon icon={"eye"} style={{fontSize: "18px"}} /> } </button>
                                        </h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        Overall sales today
                                    </span>
                                </Card.Body>
                            </Card> 
                        </Col>
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total Items Sold Today</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">
                                            { viewFigure ? formatCurrency(analytics?.daily_sales).formatted : '********' } 
                                            <button type="button" className="btn-plain" onClick={() => setViewFigure(!viewFigure)}> { viewFigure ? <FontAwesomeIcon icon={"eye-slash"} style={{fontSize: "18px"}} /> : <FontAwesomeIcon icon={"eye"} style={{fontSize: "18px"}} /> } </button>
                                        </h3>
                                    </div>
                                    <span className="currency-wrapper">
                                       Total items sold 
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4} className="mb-2">
                            <Card className="border-0 shadow-sm bg--dark rounded-14">
                                <Card.Body className="align-items-center gap-3 p-3">
                                    <p className="text-muted font-bold">Total Profit Made Today</p>
                                    <div className="mb-2">
                                        <h3 className="font-bold">
                                            { viewFigure ? formatCurrency(analytics?.daily_profit).with_currency : '********'}
                                            <button type="button" className="btn-plain" onClick={() => setViewFigure(!viewFigure)}> { viewFigure ? <FontAwesomeIcon icon={"eye-slash"} style={{fontSize: "18px"}} /> : <FontAwesomeIcon icon={"eye"} style={{fontSize: "18px"}} /> } </button>
                                        </h3>
                                    </div>
                                    <span className="currency-wrapper">
                                        Total profit today
                                    </span>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col md={12} sm={12}>
                            <RecordFilter
                                handleInput={handleInput}
                                handleSearchSubmit={loadSales}
                                params={[
                                    {label: 'SKU', value: 'sku'},
                                    {label: 'Status', value: 'status'},
                                    {label: 'Payment Method', value: 'payment_method'},
                                    {label: 'Date', value: 'date'},
                                ]}
                            />
                            <Card className="border-0 shadow-sm rounded-14">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between">
                                    Sales History
                                    <div className="justify-content-end">
                                        <input  
                                            type="date" 
                                            name="from_date" 
                                            className="form-control-sm" 
                                            onChange={handleInput}   
                                        />
                                        <input 
                                            type="date" 
                                            name="to_date" 
                                            className="form-control-sm" 
                                            placeholder="To Date" 
                                            onChange={handleInput}
                                        />
                                        <Button 
                                            type="button" 
                                            variant="primary text-white" 
                                            onClick={downloadChart}
                                        > Download Report <FontAwesomeIcon icon={'cloud-download'}/> 
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Card.Body className="table-responsive">
                                    <Table size="sm" className="table-color">
                                            <thead>
                                                <tr>
                                                    <th>SKU</th>
                                                    <th>Item</th>
                                                    <th>Sales Price(₦)</th>
                                                    <th>Quantity</th>
                                                    <th>Toal Price(₦)</th>
                                                    <th>Payment Method</th>
                                                    <th>Date Sold</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody className="data-container">
                                                {
                                                    loading ? (
                                                        <div className="in-page-loader">
                                                            <Spinner size="lg" variant="primary"/>
                                                        </div>
                                                    ) : null
                                                }
                                                {
                                                    results?.length ? (
                                                        results?.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                   {index+1}. {item.product?.sku}
                                                                </td>
                                                                <td>{item.product?.name}</td>
                                                                <td>{formatCurrency(item.price).with_currency} </td>
                                                                <td>{formatCurrency(item.quantity).formatted} </td>
                                                                <td>{formatCurrency(item.total).with_currency} </td>
                                                                <td>{item?.payment_method}</td>
                                                                <td>{dateFormatter(item.created_at)}</td>
                                                                <td>{item.status}</td>
                                                            </tr>
                                                        ))
                                                    )
                                                    : 
                                                    (
                                                        <tr>
                                                            <td colSpan={5} className="text-center text-muted">No data found</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                            {
                                results?.length ? (
                                    <div className="table-responsive mt-2">
                                        <Paginator
                                            next={paginationData.next}
                                            prev={paginationData.prev}
                                            setCurrentPage={ handlePagination }
                                        />
                                    </div>
                                ) : null
                            }
                        </Col>
                        <Col md={12} sm={12} className="mt-3">
                            <Card className="border-0 shadow-sm rounded-14">
                                <Card.Header className="bg-primary text-white d-flex justify-content-between align-items-center">
                                    Sales Chart
                                    <div>
                                        <div className="input-group">
                                            <Button 
                                                type="button" 
                                                variant="primary text-white" 
                                                onClick={generatePdf}
                                            > Download <FontAwesomeIcon icon={'cloud-download'}/> 
                                            </Button>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="h-100" id="pdf-content">
                                        <Bar data={data} options={options} />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </Container>
        </Layout>
    );
}

export default SalesTracker;