import React, { useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug, faPlugCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { connectToInventory, disconnectFromInventory, loginCustomer } from '../redux/slices/inventory.slice';
import Swal from 'sweetalert2';

const InventoryConnectionButton = () => {
  const dispatch = useDispatch();
  
  const { invLogin = false } = useSelector((state) => state.inventory);
  const { business = {} } = useSelector((state) => state.user);
  const { user = {} } = useSelector((state) => state.auth);
  
  const isInventoryConnected = invLogin;
  const [loading, setLoading] = useState(false);

  const handleToggleConnection = async () => {
    setLoading(true);
    try {
      if (isInventoryConnected) {
        await dispatch(disconnectFromInventory()).unwrap();
        // connectionStatus?.(false); 
      } else {
        const connectPayload = {
          business_name: business?.store_name || user?.store_name,
          first_name: user?.first_name || '',
          last_name: user?.last_name || '',
          email: user?.email || '',
          phone_number: user?.mobile || '',
          wallx_id: business?.merchant_id || user?.merchant_id,
          user_type: user?.user_type || 'manager',
        };
        
        const connectResult = await dispatch(connectToInventory(connectPayload)).unwrap();
        if (connectResult?.status === true) {
          await dispatch(loginCustomer({ 
            email: user?.email || '', 
            password: business?.merchant_id || user?.merchant_id
          })).unwrap();
        //   connectionStatus?.(true); 
        } else {
          throw new Error('Connection response status was not true');
        }
      }
    } catch (error) {
      console.error('Failed to toggle inventory connection:', error.message);
      Swal.fire({
        icon: 'error',
        text: 'Failed to connect to inventory: ' + error.message
       }); 
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      variant={isInventoryConnected ? 'danger' : 'primary'}
      onClick={handleToggleConnection}
      disabled={loading} 
      style={{
        position: 'fixed',
        top: '20%',
        right: '20px',
        borderRadius: '50%',
        width: '60px',
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1000,
        boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
      }}
    >
      {loading ? (
        <Spinner variant="light" size="sm" />
      ) : (
        <FontAwesomeIcon
          icon={isInventoryConnected ? faPlugCircleXmark : faPlug}
          size="lg"
        />
      )}
    </Button>
  );
};

export default InventoryConnectionButton;