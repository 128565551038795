import { useState, useEffect } from "react";
import { Col, Container, Form, Row, Button, InputGroup, Spinner } from "react-bootstrap";
import Logo from "../../../components/Logo.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../../redux/slices/manager.auth.slice.js";
import { clearMessage } from '../../../redux/slices/message.js'
import AlertModal from "../../../components/modal/AlertModal.jsx";
import Swal from "sweetalert2";
// import { logout } from "../../../redux/slices/auth.js";
import { getMemberAccounts } from "../../../redux/slices/account.service.js";

const ManagerLogin = () => {

    const [show_password, setShowPassword] = useState(false)
    const [is_active, setIsActive] = useState(false)
    const [payload, setPayload] = useState({email: '', password: ''})
    const [loading, setLoading] = useState(false)
    const [alertMessage, setAlertMessage] = useState(false)
    const [alertText, setAlertText] = useState(null)
    
    const navigate = useNavigate();

    const dispatch = useDispatch()

    const { user, isLoggedIn } = useSelector((state) => state.auth)

    useEffect(() => {
        if (user && isLoggedIn && user.user_type === 'manager') { 
            navigate('/members/dashboard')
        }
    }, [isLoggedIn, user])

    const togglePassword = () => {
        setShowPassword(!show_password)
    }

    const handleInput = (event) => {
        const { name, value } = event.target
        setPayload((prevState)=> ({...prevState,[name]:value}))
        const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if((payload.email && payload.email.match(pattern)) && payload.password) 
            setIsActive(true)
        else 
            setIsActive(false)
    }

    const forgotPassword = () => {
        navigate('/members/forgot-password')
    }

    const handleLogin = async () => {
        setLoading(true)
        dispatch(login(payload)) 
        .then((response) => {
            if (response.payload?.success === false || response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
                dispatch(clearMessage())
            }
            else if(response.payload?.success === true) {
                navigate('/members/dashboard')
                Swal.fire({
                    'icon': 'success',
                    text: response.payload?.message
                })
                dispatch(getMemberAccounts())
                .then((res) => {
                    // window.location.reload()
                })
            }
        })
        .finally(() => setLoading(false))
    }

    
    return (
        <Row className="full-height">
            <Col md={6} lg={6} sm={12} className="px-5">
                <Container className="mt-5 text-center px-5">
                    <div className="mt-3 mb-5">
                        <Logo variant="dark"/>
                    </div>
                    <h1 className="text-primary mt-5">👋 Welcome Back, Manager</h1>
                    <p className="text-dark">
                        Login to your dashboard to see some transactions/activities you might have missed.
                    </p>
                </Container>
                <Container className="px-5 px-md-5 mt-5">
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} md={12} sm={12} lg={12} controlId="validationCustom01" className="mb-3">
                                <Form.Label>Email address </Form.Label>
                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Email"
                                    name="email"
                                    size="lg"
                                    onChange={handleInput}
                                />
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                <FontAwesomeIcon icon="fa fa-circle-info"/>
                                    <span className="px-1">Enter a valid email</span>
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md={12} sm={12} lg={12} controlId="validationCustomUsername" className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <InputGroup hasValidation>
                                    <Form.Control
                                        type={show_password ? "text" : "password"}
                                        placeholder="Password"
                                        aria-describedby="inputGroupPrepend"
                                        required
                                        size="lg"
                                        name="password"
                                        onChange={handleInput}
                                    />
                                    <InputGroup.Text id="inputGroupPrepend" onClick={togglePassword}>
                                        <Button type="button" variant="light" className="border-0 input-group-btn-bg">
                                            <FontAwesomeIcon icon={!show_password ? "far fa-eye-slash" : "far fa-eye"} />
                                        </Button>
                                    </InputGroup.Text>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    className="bg-white border-0 text-primary"
                                    onClick={forgotPassword}
                                >
                                    Forgot password?
                                </Button>
                            </Form.Group>
                        </Row>
                        <div>
                            <Button 
                                type="button" 
                                size="lg" 
                                onClick={handleLogin}
                                className="form-control"
                            >
                                Login <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                            </Button>
                        </div>
                    </Form>
                </Container>
            </Col>
            <Col md={6} lg={6} sm={12} className="bg-primary p-0 d-sm-none d-md-block">
                <Container className="mt-5 mb-2 ">
                    <Col className="text-white text-center px-5">
                        <h2>Share , Spend with Ease</h2>
                        <p>
                            Join WallX to easily conduct socio-lifestyle transactions. Suitbale for both businesses and individuals
                        </p>
                    </Col>
                </Container>
                <div className="login-banner">
                    <img src={require('../../../assets/images/landing_page/dashboard.png')} alt="dashboard" />
                </div>
            </Col>
            <AlertModal
                text={alertText}
                onHide={() => setAlertMessage(false)}
                onClick={() => setAlertMessage(false)}
                show={alertMessage}
                buttonText="Ok"
            >
                <FontAwesomeIcon className="text-success" icon="check-circle" style={{ fontSize: "54px" }} />
            </AlertModal>
        </Row>
    );
}


export default ManagerLogin;