import { Col, Row, Card, Container, Button, Modal, Form, FormGroup, Badge, Table, Spinner } from "react-bootstrap";
import Layout from "../../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import bank from '../../../assets/images/gtb.svg';
import wema from '../../../assets/images/wema.svg';
import sterling from '../../../assets/images/sterling.svg';
import monniepoint from '../../../assets/images/moniepoint.jpg';

import { useState, useEffect, useRef } from "react";
import { QRCodeSVG } from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import { applyPayCode, getProfile, getTransactionHistory } from "../../../redux/slices/managers.slice";
import Swal from "sweetalert2";
import formatCurrency from "../../../utils/currencyFormmter";
import { checkBalance, getMemberAccounts } from "../../../redux/slices/account.service";
import { fundDeposit, getMemberBusiness } from "../../../redux/slices/users";
import dateFormatter from "../../../utils/dateFormatter";
import yellowdot from '../../../assets/images/yellowdot.svg';
import toast from "react-hot-toast";
import DrewsModal from "../../../components/modal/DrewsModal";
import axios from "axios";
import { PaystackButton } from "react-paystack";

const ManagerDashboard = () => {

    const dispatch = useDispatch()
    // const navigate = useNavigate()
    
    const { user } = useSelector((state) => state.auth)
    const { accounts } = useSelector((state) => state.user)

    const isLoaded = useRef(false)

    const [payload, setPayload] = useState({amount: null})
    const [loading, setLoading] = useState(false)
    const [fetching, setFetching] = useState(false)
    const [errors, setErrors] = useState({})
    const [ready_to_scan, toggleScan] = useState(false)
    const [results, setResults] = useState([])
    const [claim_code, setClaimPaycode] = useState(false)
    const [show_balance, setShowBalance] = useState(false)
    const [copiedText, setCopiedText] = useState()
    const [errorMessage, setErrorMessage] = useState(null)
    const [virtual_accounts, setAccounts] = useState([])
    const [paystackKey, setPaystackKey] = useState('')
    const [isWalletFunding, setWalletFunding] = useState(false)

    const componentProps = {
        email: user?.email,
        amount: payload.fund_amount ? (parseFloat(payload.fund_amount) * 100) : 0,
        metadata: {
            phone: user?.phone,
        },
        publicKey: paystackKey, 
        text: "Fund Wallet",
        onSuccess: (data) =>  {
            paymentVerification(data.trxref)
        },   
        // onClose: () => alert("Wait! You need this oil, don't go!!!!"),
    }

    const banks = {
        'wema-bank': wema,
        'sterling-bank': sterling,
        'gt-bank': bank,
        'moniepoint-bank': monniepoint,
        'moniepoint': monniepoint,
        'moniepoint-microfinance-bank': monniepoint,
    }
    

    useEffect(() => {
        if(!isLoaded.current) {
            isLoaded.current = true
            loadAppData()
            loadTransactions()
        }
    }, [])


    const handleInput = (event) => {
        const { name, value } = event.target
        if(name === 'amount' || name === 'fund_amount') {
            const newValue = value.replace(/[^\d.]/g, '')
            setPayload((prevState)=> ({...prevState,[name]:newValue}))
        }
        else {
            setPayload((prevState)=> ({...prevState,[name]:value}))
        }
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if ((name ==='amount' || name === 'fund_amount') && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if (name ==='amount' && parseFloat(value.replace(/[^\d.]/g, '')) > 200000) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: 'You can not withdraw above maximum limit' }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }

    const loadAppData = () => {
        loadAccounts()
        dispatch(getMemberBusiness())
        .then((response) => {
            // console.log("RESPONSE", response)
        })
    }

    const loadTransactions = () =>  {
        dispatch(getTransactionHistory())
        .then((response) => {
            if(response.payload.results) {
                setResults(response.payload.results)
            }
        })
    }

    const loadAccounts = () =>  {
        setFetching(true)
        dispatch(getMemberAccounts())
        .then((response) => {
            if(response?.payload?.data) {
                setAccounts(response.payload.data)
            }
            else {
                setAccounts(accounts)
            }
        })
        .finally(() => setFetching(false))
    }

    const usePaycode = () => {
        if(!payload.amount && !payload.pin && !payload.secret)  { return }
        // console.log(payload)
        setLoading(true)
        dispatch(applyPayCode(payload))
        .then((response) => {
            setLoading(false)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => setClaimPaycode(false))
            }
            else {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
    }

    const handleCopy = (copyText) => {
        navigator.clipboard.writeText(copyText)
        .then(() => {
            setCopiedText(copyText);
            toast.success(`Copied!`, {  
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,                            
              onClose: () => setCopiedText(false),
            });
        })
    };

    const paycodeBalance = () => {
        if(!payload.paycode && !payload.secret_word)  {  return }
        setLoading(true)
        dispatch(checkBalance(payload))
        .then((response) => {
            if(response.payload?.success) {
                Swal.fire({
                    icon: 'info',
                    text: response.payload?.message
                })
                .then(() => setShowBalance(false))
            }
        })
        .finally(() => setLoading(false))
    }

    const getUserProfile = () => { 
        // if(user.user_type !== 'owner') {
        //     navigate('/members/dashboard')
        //     return;
        // }
        dispatch(getProfile())
        .then((response) => {
            let msg = '';
            let data = response.payload
            console.log("PROFILE", response)

            // if(data && data.kyc_verified == false) {
            //     setKycForm(false)
            // }
            // else 
            // if(data && data.user_type !== 'owner') {
            //     navigate('/members/dashboard')
            // }
            // else if(data && data.email && data.verified == false) {
            //     msg =  'Your account is not verified. Please contact support'
            // } 
            // // else if(data && data.user_type !== 'owner') {
            // //     navigate('/members/dashboard')
            // // }
            // else if(data && data.email && !data.store_created) {
            //     navigate('/profile-setup') 
            // } 
            // setState(({...state, profile: data}))
        })
    }

    const getThirdPartyKeys = () => {
        setWalletFunding(true)
        const credentials = `${process.env.REACT_APP_CLIENT_USER}:${process.env.REACT_APP_CLIENT_KEY}`
        const base64Credentials = btoa(credentials)
        axios.get(`${process.env.NODE_ENV === 'production' ? process.env.REACT_APP_BASE_URL_PROD : process.env.REACT_APP_BASE_URL_DEV}/api-v1/third_party_keys/`, {
            headers: {
                Authorization: `Basic ${base64Credentials}`
            }
        })
        .then(response => {
            if(response.data.data !== 'undefined') {
                const data = response.data.data
                if(data.pay_stack_live_key) {
                    setPaystackKey(data.pay_stack_public_key)
                }
            }
        }) 
        .catch(err => {
            // console.log(err)
        })
    }

    const paymentVerification = (reference) => {
        setLoading(true)
        dispatch(fundDeposit({reference: reference}))
        .then((response) => {
            // console.log("FUNDING", response)
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
                .then(() => {
                    setWalletFunding(false)
                    // loadWallets()
                })
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
    }

    return (
        <Layout>
            <Container className="mt-5">
                <Row className="mb-2">
                    <Col md={4}>
                        <h3 className="mb-0">Hello, { user?.first_name+' '+user?.last_name }</h3>
                        <p className="text-primary font-bold">Welcome to Manager Dashboard for {user?.store_name}.</p>
                    </Col>
                    <Col md={8}>
                        <div className="text-right">
                            <Button 
                                type="button" 
                                variant="light" 
                                className="text-white bg-primary border-0 mb-2"
                                onClick={ () => toggleScan(!ready_to_scan)}
                            >
                                <FontAwesomeIcon icon='qrcode' />
                                <span className="ps-2">Ready to Scan</span>
                            </Button>
                            <Button 
                                type="button" 
                                variant="outline-primary" 
                                className="mx-2 mb-2"
                                onClick={ () => toggleScan(false)}
                            >
                                <FontAwesomeIcon icon='qrcode' />
                                <span className="ps-2">Stop Scan</span>
                            </Button>
                            <Button 
                                type="button" 
                                variant="light" 
                                className="text-white bg-primary border-0 mb-2 mx-2"
                                onClick={ () => setShowBalance(true)}
                            >
                                <span className="ps-2">Check Balance</span>
                            </Button>
                            <Button 
                                type="button" 
                                variant="light" 
                                className="text-white bg-primary border-0 mb-2"
                                onClick={() => setClaimPaycode(true)}
                            >
                                <FontAwesomeIcon icon='plus' />
                                <span className="ps-2">Claim Voucher</span>
                            </Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <Card className="shadow-sm mb-3">
                                <Card.Header className="border-0 bg-primary">
                                    <p className="m-0 text-white">SCAN TO PAY</p>
                                </Card.Header>
                                <Card.Body>
                                    <div className="bg-light p-4 text-center qr-img rounded-16">
                                        <QRCodeSVG 
                                            value={`https://businesses.wallx.co/payments/qr-pay/${user?.merchant_id}`}
                                            size={235}
                                        />
                                    </div>
                                </Card.Body>
                        </Card>
                    </Col>
                    
                    <Col md={8}>
                        <Card className={`shadow-sm mb-3 ${ready_to_scan ? "freeze" : ''} `}>
                            <Card.Header className="border-0 bg-primary text-white d-flex justify-content-between">
                                BANK ACCOUNTS
                                <Button 
                                    type="button" 
                                    variant="light" 
                                    onClick={ getThirdPartyKeys }
                                >
                                    <FontAwesomeIcon icon={"plus"} style={{fontSize: "20px"}}/>
                                    <span className="px-2">Top-up Wallet</span>
                                </Button>
                            </Card.Header>
                            <Card.Body>
                                <Row className="mt-5 mb-5">
                                    {
                                        // typeof(virtual_accounts) === 'object' && virtual_accounts?.length ? (
                                            <Col md={6}>
                                                <Card className="mb-3">
                                                    <Card.Header className="border-0 bg-white">
                                                        <p className="m-0 title">YOUR BUSINESS ACCOUNT</p>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        {
                                                            fetching ? (
                                                                <div className="in-page-loader">
                                                                    <Spinner size="lg" variant="primary"/>
                                                                </div>
                                                            ) : null 
                                                        }
                                                        {
                                                            virtual_accounts && virtual_accounts.length > 0 ? virtual_accounts?.map((account, index) => (
                                                                <div className="mb-2 d-flex align-items-center" key={index}>
                                                                    <img 
                                                                        // src={account.bank_slug === 'wema-bank' ? wema : bank} 
                                                                        src={banks[account.bank_slug] ?? bank} 
                                                                        alt={account.bank_slug} 
                                                                        width={70} 
                                                                        className="thumbnail-image rounded-circle border border-1" 
                                                                    />
                                                                    <div className="w-75 px-4 divider pb-4">
                                                                        <strong className="d-block">{account.account_number}</strong>
                                                                        <span className="text-muted">{account.account_name}</span> 
                                                                        <img src={yellowdot} alt="dot" className="ms-2 me-2" />
                                                                        <span className="text-muted">{account.bank_name}</span>
                                                                    </div>
                                                                    <Button 
                                                                        type="button" 
                                                                        variant="light" 
                                                                        className="bg-white text-primary border-0"
                                                                        onClick={() => handleCopy(`${account?.account_number} ${account?.bank_name} - ${account?.account_name}`)}
                                                                    >
                                                                        <FontAwesomeIcon icon="far fa-copy" style={{fontSize: "20px"}}/>
                                                                    </Button>
                                                                </div>
                                                            )) : 
                                                            <div>
                                                                <p className="text-center">
                                                                    Could not load account. 
                                                                    <button 
                                                                        type="button" 
                                                                        className="btn btn-plain text-primary btn-sm font-bold"
                                                                        onClick={loadAccounts}
                                                                    >
                                                                        Refresh
                                                                    </button> 
                                                                </p>
                                                            </div>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </Col>       
                                        // ) : null
                                    }
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={12} xs={12} sm={12} lg={12} className="mt-4">
                        <Card className={`shadow-sm ${ready_to_scan ? 'freeze' : ''}`}>
                            <Card.Header className="d-flex align-items-center border-0 bg-transparent">
                               <h5 className="mb-0">Recent Transactions </h5> 
                                <button className="btn-plain text-primary font-bold text-sm">View All</button> 
                            </Card.Header>
                            <Card.Body className="table-responsive">
                                <Table size="sm" className="table-color table-striped">
                                    <thead>
                                        <tr>
                                            <th>Details</th>
                                            <th>Order</th>
                                            <th>Amount</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            results?.length ? (
                                                results?.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <div className="m-0 d-flex gap-2 align-items-center">
                                                                <small className={item.status === 'success' ? 'drews-badge drews-badge-success-circle' : 'drews-badge drews-badge-error-circle'}>
                                                                    <i className="lni lni-arrow-top-right"></i>
                                                                </small>
                                                                <strong>
                                                                  <p className="m-0">{item.reference}</p>  
                                                                  <small>{dateFormatter(item.date)}</small>
                                                                </strong>
                                                            </div>
                                                        </td>
                                                        <td>  
                                                            <p className="m-0">{item.order}</p>
                                                            <span className={'font-bold '+item.status === 'success' ? 'text-success' : 'text-danger'}>{item.transaction_type}</span>
                                                        </td>
                                                        <td>{formatCurrency(item.amount).with_currency} </td>
                                                        <td>
                                                            <p className="d-flex m-0 align-items-center justify-content-between">
                                                                <Badge 
                                                                    className={item.status === 'success' ? 'badge-active' : 
                                                                    (item.status === 'failed' ? 'badge-failed' : 
                                                                    (item.status === 'used' ? 'badge-used' : 'badge-pending'))}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                                                                        <circle cx="5" cy="5" r="5" fill={
                                                                            item.status === 'success' ? "#008000" : 
                                                                            (item.status === 'failed' ? "#EB5757" : 
                                                                            (item.status === 'used' ? "#828282" : "#FAB941"))
                                                                        }
                                                                        />
                                                                    </svg>
                                                                    <span className="ps-1">
                                                                        {item.status}
                                                                    </span>
                                                                </Badge> 
                                                            </p>
                                                        </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Wallet funding modal */}
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={claim_code}
                    onHide={() => setClaimPaycode(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>Claim Voucher</Modal.Header>
                    <Modal.Body className="px-4">
                        <Form>
                            <Row>
                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label className="mb-0">PayCode</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="PayCode"
                                        name="pin"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        minLength={6}
                                        maxLength={6}
                                    />
                                    {
                                        errors['pin'] && 
                                        <Form.Text className="text-danger">{errors['pin']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label className="mb-0">Secret Word</Form.Label>
                                    <Form.Control
                                        type="password"
                                        required
                                        placeholder="Secret Word"
                                        name="secret"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                    />
                                    {
                                        errors['secret'] && 
                                        <Form.Text className="text-danger">{errors['secret']}</Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="12" className="mb-3">
                                    <Form.Label className="mb-0">Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="0.00"
                                        name="amount"
                                        onChange={handleInput}
                                        onKeyUp={handleValidation}
                                        value={ parseFloat(payload.amount) > 0 ? formatCurrency(payload.amount).formatted : ''}
                                    />
                                    {
                                        errors['amount'] && 
                                        <Form.Text className="text-danger">{errors['amount']}</Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group 
                                    as={Col} 
                                    md="12" 
                                    controlId="validationCustom011" 
                                    className="mt-4 d-grid"
                                >
                                    <Button 
                                        variant="primary" 
                                        size="lg" 
                                        onClick={usePaycode}
                                        disabled={Object.keys(errors).length > 0}
                                    >
                                        Apply PayCode <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                    </Button>
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                </Modal>

                <DrewsModal
                    show={show_balance}
                    onHide={() => setShowBalance(false)}
                    size="md"
                    dismissable={true}
                    title="Voucher Balance"
                >
                    <Form>
                        <Row>
                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Voucher's Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="PayCode"
                                    name="paycode"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    minLength={6}
                                    maxLength={6}
                                />
                                {
                                    errors['paycode'] && 
                                    <Form.Text className="text-danger">{errors['paycode']}</Form.Text>
                                }
                            </Form.Group>

                            <Form.Group as={Col} md="12" className="mb-3">
                                <Form.Label className="mb-0">Secret Word</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="Secret Word"
                                    name="secret_word"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                />
                                {
                                    errors['secret_word'] && 
                                    <Form.Text className="text-danger">{errors['secret_word']}</Form.Text>
                                }
                            </Form.Group>
                                <p className="text-danger text-sm">
                                    {errorMessage}
                                </p>
                            <Form.Group 
                                as={Col} 
                                md="12" 
                                controlId="validationCustom011" 
                                className="mt-4 d-grid"
                            >
                                <Button 
                                    variant="primary" 
                                    size="lg" 
                                    onClick={paycodeBalance}
                                    disabled={Object.keys(errors).length > 0}
                                >
                                    Check Balance <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                </Button>
                            </Form.Group>
                        </Row>
                    </Form>
                </DrewsModal>

                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={isWalletFunding}
                    onHide={() => setWalletFunding(false)}
                    backdrop="static"
                >
                    <Modal.Header closeButton>Fund your Wallet</Modal.Header>
                    <Modal.Body className="p-5">
                        <Row>
                            <Form.Group as={Col} md={12} controlId="validationCustom01" className="mb-3">
                                <Form.Label className="mb-0 text-sm">Amount</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="₦0.00"
                                    name="fund_amount"
                                    onChange={handleInput}
                                    onKeyUp={handleValidation}
                                    value={ parseFloat(payload.fund_amount) > 0 ? formatCurrency(payload.fund_amount).formatted : ''}
                                />
                                {
                                    errors['fund_amount'] && 
                                    <Form.Text className="text-danger">{errors['fund_amount']}</Form.Text>
                                }
                            </Form.Group>
                        </Row>
                        <Form.Group className="mt-4">
                            <PaystackButton className="btn btn-primary form-control" {...componentProps} />
                        </Form.Group>
                    </Modal.Body>
                </Modal>
            </Container>
        </Layout>
    );
}

export default ManagerDashboard;