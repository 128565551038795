import { Menu, MenuItem, SubMenu, Sidebar } from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { Button, Card, Container, Form, Row, Col, Spinner} from "react-bootstrap";
import logo from '../assets/images/logo_white.svg';
import crown from '../assets/images/crown.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import DrewsModal from "./modal/DrewsModal";
import Swal from "sweetalert2";
import { upgradeAccount } from "../redux/slices/users";
import { menus } from "../utils/menu/menu";
import { setMenu } from "../redux/slices/menu.nav.slice";
import { logout } from "../redux/slices/managers.slice";
import star from '../assets/images/stars.svg'
import { sidebarToggle } from "../redux/slices/auth";
import { manager_menu } from "../utils/menu/manager_menu";

const AppSidebar = () => {

    const dispatch = useDispatch()

    // const navigate = useNavigate()
    
    const { business } = useSelector((state) => state.user)
    const { menu } = useSelector((state) => state.menu)
    const { isManager, user, sidebar } = useSelector((state) => state.auth)

    const [menu_item, setActiveMenu] = useState(menu?.name)
    const [showform, setShowForm] = useState(false)
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({})
    const [errors, setErrors] = useState({})
    const [cac, setCac] = useState(null)
    // const [toggled, setToggled] = useState(false)

    useEffect(() => {
        if(menu?.name) {
            setActiveMenu(menu.name)
        }
    },[0])

    const handleInput = (event) => {
        const {name, value} = event.target
        setPayload((prevState) => ({...prevState, [name]: value}))
    }

    const handleValidation = (event) => {

        const regex = /^\d*\.?\d*$/;
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    
        const {name, value} = event.target
        if(value === '' && event.target.attributes.required) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else if(name === 'email' && !pattern.test(value)) {
            setErrors((prevState) => ({ ...prevState, [name]: 'Please enter a valid email address'}))
        }
        else if (name ==='amount' && !regex.test(parseFloat(value.replace(/[^\d.]/g, '')))) {
            event.target.style = "border: 1px solid red"
            setErrors((prevState) => ({ ...prevState, [name]: `${name[0].toUpperCase() + name.split('_').join(' ').slice(1)} is required` }))
        }
        else {
            event.target.style = "border-style: transparent"
            delete errors[name]
            setErrors(errors)
        }
    }

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
            setCac(file)
        };
    }

    const businessUpgrade = () => {
        if(!payload.business_name && !payload.rc_number) { return }
        let formdata = new FormData()
        formdata.append('business_name', payload.business_name)
        formdata.append('rc_number', payload.rc_number)
        formdata.append('cac', cac)
        setLoading(true)
        dispatch(upgradeAccount(formdata))
        .then((response) => {
            if(response.payload?.success === true) {
                Swal.fire({
                    icon: 'success',
                    text: response.payload?.message
                })
            }
            else if(response.payload?.success === 'False') {
                Swal.fire({
                    icon: 'error',
                    text: response.payload?.message
                })
            }
        })
        .finally(() => setLoading(false))
        
    }

    const menuClasses = {
        button: 'ps-menuitem-root', 
        subMenuContent: 'ps-submenu-content', 
        menuButton: 'ps-menu-button'
    }

   const handleNavigation = (item) => {
        dispatch(setMenu({name: item, status: true}))
   }

   const handleLogout = () => {
        dispatch(logout())
   }

    const menuToggle = () => {
        dispatch(sidebarToggle())
    }

    return (
        <>
            <div className={`sidebar-plain`}>
                <Sidebar 
                    backgroundColor="#443792" 
                    className="no-print sdidebar" 
                    onBackdropClick={ menuToggle } 
                    toggled={sidebar} 
                    breakPoint="sm"
                >
                    <div className="text-center py-2 mb-3 sidebar-logo">
                        <img src={logo} alt="logo" />
                        <p className="text-white font-bold m-0 text-sm">Version 2.0</p>
                        <p className="id-wrapper mt-3">
                            Merchant ID: {user?.user_type === 'owner' ? business?.merchant_id : user?.merchant_id }
                        </p>
                    </div>
                    <div className="px-2 pb-4" style={{ height: '90vh', minHeight: '90vh'}}>
                        <Menu
                            menuItemStyles={{
                                button: ({ level, active, disabled }) => {
                                    return {
                                    color: active ? '#443792' : '#ffffff',
                                    backgroundColor: active ? '#F7F6FF' : '#443792',
                                    fontSize: '15px',
                                    paddingLeft: '10px',
                                    borderRadius: '8px',
                                        '&:hover': {
                                            backgroundColor: '#F7F6FF',
                                            borderRadius: "8px",
                                            color: "#443792"
                                        },
                                    };
                                },
                            }}
                        >
                            {
                                menus.length && user?.user_type === 'owner' ? (
                                    menus.map((item, index) => (
                                        item.children ? 
                                            <SubMenu 
                                                key={index}
                                                label={item.name}
                                                rootStyles={{ 
                                                    borderTop: "1px solid rgba(242, 242, 242, 0.20)",
                                                    ['& > .' + menuClasses.button]: {
                                                        backgroundColor: '#eaabff',
                                                        color: '#f2f2f2',
                                                        '&:hover': {
                                                            backgroundColor: '#eecef9',
                                                        },
                                                        },
                                                    ['.' + menuClasses.subMenuContent]: {
                                                        backgroundColor: 'transparent',
                                                    },
                                                }}
                                                defaultOpen={ item.children.filter(itm => itm.alias.includes(menu_item)).length }
                                            >
                                                {
                                                    item.children.length ? (
                                                        item.children.map((childItem, childIndex) => (
                                                            <MenuItem
                                                                key={childIndex}
                                                                icon={ 
                                                                    childItem.icon_type === 'lni' ?  
                                                                    <i className={childItem.icon} style={{fontSize: 20}}></i> : 
                                                                    childItem.icon_type === 'svg' ? <img src={star} /> :
                                                                    <FontAwesomeIcon icon={childItem.icon} style={{ fontSize: 20 }} />
                                                                } 
                                                                component={<Link to={childItem.url}/>}
                                                                rootStyles={{
                                                                    ['.' + menuClasses.menuButton]: {
                                                                        padding: "0px",
                                                                    },
                                                                }}
                                                                onClick={() => handleNavigation(childItem.alias)}
                                                                active={ menu_item === childItem.alias }
                                                            >
                                                                {childItem.name} { childItem.is_new ? (<span className="new-menu">New</span>) : null }
                                                            </MenuItem>

                                                        )) 
                                                    ) : null
                                                }
                                            </SubMenu>
                                        :
                                        <MenuItem 
                                            key={index}
                                            component={<Link to={item.url}/>}
                                            icon={ 
                                                item.icon_type === 'lni' ?  
                                                <i className={item.icon} style={{fontSize: 20}}></i> : 
                                                <FontAwesomeIcon icon={item.icon} style={{ fontSize: 20 }} />
                                            } 
                                            active={ menu_item === item.alias || menu_item === null }
                                            onClick={() => handleNavigation(item.alias)}
                                        >
                                            {item.name} { item.is_new ? (<span className="new-menu">New</span>) : null }
                                        </MenuItem>
                                    ))
                                ) : 
                                manager_menu.length && (
                                    manager_menu.map((item, index) => (
                                        item.children ? 
                                            <SubMenu 
                                                key={index}
                                                label={item.name}
                                                rootStyles={{ 
                                                    borderTop: "1px solid rgba(242, 242, 242, 0.20)",
                                                    ['& > .' + menuClasses.button]: {
                                                        backgroundColor: '#eaabff',
                                                        color: '#f2f2f2',
                                                        '&:hover': {
                                                            backgroundColor: '#eecef9',
                                                        },
                                                        },
                                                    ['.' + menuClasses.subMenuContent]: {
                                                        backgroundColor: 'transparent',
                                                    },
                                                }}
                                                defaultOpen={ item.children.filter(itm => itm.alias.includes(menu_item)).length }
                                            >
                                                {
                                                    item.children.length ? (
                                                        item.children.map((childItem, childIndex) => (
                                                            <MenuItem
                                                                key={childIndex}
                                                                icon={ 
                                                                    childItem.icon_type === 'lni' ?  
                                                                    <i className={childItem.icon} style={{fontSize: 20}}></i> : 
                                                                    childItem.icon_type === 'svg' ? <img src={star} /> :
                                                                    <FontAwesomeIcon icon={childItem.icon} style={{ fontSize: 20 }} />
                                                                } 
                                                                component={<Link to={childItem.url}/>}
                                                                rootStyles={{
                                                                    ['.' + menuClasses.menuButton]: {
                                                                        padding: "0px",
                                                                    },
                                                                }}
                                                                onClick={() => handleNavigation(childItem.alias)}
                                                                active={ menu_item === childItem.alias }
                                                            >
                                                                {childItem.name} { childItem.is_new ? (<span className="new-menu">New</span>) : null }
                                                            </MenuItem>

                                                        )) 
                                                    ) : null
                                                }
                                            </SubMenu>
                                        :
                                            <MenuItem 
                                                key={index}
                                                component={<Link to={item.url}/>}
                                                icon={ 
                                                    item.icon_type === 'lni' ?  
                                                    <i className={item.icon} style={{fontSize: 20}}></i> : 
                                                    <FontAwesomeIcon icon={item.icon} style={{ fontSize: 20 }} />
                                                } 
                                                active={ menu_item === item.alias || menu_item === null }
                                                onClick={() => handleNavigation(item.alias)}
                                            >
                                                {item.name}
                                            </MenuItem>
                                    ))
                                ) 
                                // (
                                //     <>
                                //         <MenuItem 
                                //             component={<Link to="/members/dashboard"/>}
                                //             icon={<i className="lni lni-grid-alt" style={{fontSize: 20}}></i>} 
                                //             active={ menu_item === 'dashboard' || menu_item === null }
                                //             onClick={() => handleNavigation('dashboard')}
                                //         >
                                //             Dashboard
                                //         </MenuItem>
                                //         <MenuItem 
                                //             component={<Link to="/members/transactions"/>} 
                                //             icon={<FontAwesomeIcon icon="fa fa-rotate-left" style={{ fontSize: 20 }} />} 
                                //             active={ menu_item === 'transactions' }
                                //             onClick={() => handleNavigation('transactions')}
                                //         >
                                //             Transaction History
                                //         </MenuItem>
                                //         <SubMenu 
                                //                 label="DIGITAL BANKING"
                                //                 rootStyles={{ 
                                //                     borderTop: "1px solid rgba(242, 242, 242, 0.20)",
                                //                     ['& > .' + menuClasses.button]: {
                                //                         backgroundColor: '#eaabff',
                                //                         color: '#f2f2f2',
                                //                         '&:hover': {
                                //                             backgroundColor: '#eecef9',
                                //                         },
                                //                         },
                                //                     ['.' + menuClasses.subMenuContent]: {
                                //                         backgroundColor: 'transparent',
                                //                     },
                                //                 }}
                                //             >
                                //             <MenuItem 
                                //                 component={<Link to="/members/paycodes"/>}
                                //                 icon={<FontAwesomeIcon icon="fa fa-money-check" style={{ fontSize: 20 }} />}
                                //                 onClick={() => handleNavigation('paycodes')}
                                //                 active={ menu_item === 'paycodes' }
                                //             > 
                                //                 Paycodes 
                                //             </MenuItem>
                                //             <MenuItem 
                                //                 component={<Link to="/members/paycodes"/>}
                                //                 icon={<FontAwesomeIcon icon="fa fa-money-check" style={{ fontSize: 20 }} />}
                                //                 onClick={() => handleNavigation('paycodes')}
                                //                 active={ menu_item === 'paycodes' }
                                //             > 
                                //                 Invoices 
                                //             </MenuItem>
                                //         </SubMenu>
                                //         <MenuItem 
                                //             component={<Link to="/members/notifications"/>}
                                //             icon={<FontAwesomeIcon icon="bell" style={{ fontSize: 20 }} />}
                                //             onClick={() => handleNavigation('notifications')}
                                //             active={ menu_item === 'notifications' }
                                //         > 
                                //             Notifications 
                                //         </MenuItem>
                                //     </>
                                // )
                            }
                        </Menu>
                        {
                            user?.user_type === 'owner' && business?.business_type === 'Starter Business' ? (
                                <Card className="sidebar-card">
                                    <Card.Body>
                                        <img src={crown} alt="crown" className="mb-3"  />
                                        <p>
                                            You are currently on a free account, upgrade to enjoy a lot of offers.
                                        </p>
                                        <Button 
                                            variant="secondary"
                                            onClick={ () => setShowForm(true) }
                                            className="w-100 mt-3"
                                        >
                                            Upgrade account
                                        </Button>
                                    </Card.Body>
                                </Card>
                            )
                            : null
                        }
                        <DrewsModal
                            show={showform}
                            onHide={() => setShowForm(false)}
                            size="md"
                            dismissable={true}
                            title="Upgrade"
                        >
                            <Form>
                                <Row>
                                    <Form.Group as={Col} md="12" className="mb-3">
                                        <Form.Label className="mb-0">Business Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="Business Name"
                                            name="business_name"
                                            onChange={handleInput}
                                            onKeyUp={handleValidation}
                                        />
                                        {
                                            errors['business_name'] && 
                                            <Form.Text className="text-danger">{errors['business_name']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" className="mb-3">
                                        <Form.Label className="mb-0">RC Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            placeholder="RC-098347"
                                            name="rc_number"
                                            onChange={handleInput}
                                            onKeyUp={handleValidation}
                                        />
                                        {
                                            errors['rc_number'] && 
                                            <Form.Text className="text-danger">{errors['rc_number']}</Form.Text>
                                        }
                                    </Form.Group>

                                    <Form.Group as={Col} md={12} lg={12} sm={12} controlId="validationCustom011" className="mb-3">
                                        <Form.Label className="mb-0 text-sm">CAC Document</Form.Label>
                                        <label htmlFor="cac" className="custom-file-select form-control form-control-lg p-3">
                                        <FontAwesomeIcon icon="fa fa-arrow-up-from-bracket" /> Upload document
                                            <input
                                                type="file"
                                                name="cac"
                                                onChange={handleFileSelect}
                                                accept="image/*"
                                                size="lg"
                                                id="cac"
                                            />
                                        </label>
                                        <p className="px-1 m-0 text-secondary text-sm">Only png or jpg file is allowed. Max files size 2mb</p>
                                    </Form.Group>

                                    <Form.Group 
                                        as={Col} 
                                        md="12" 
                                        controlId="validationCustom011" 
                                        className="mt-4 d-grid"
                                    >
                                        <Button 
                                            variant="primary" 
                                            size="lg" 
                                            onClick={businessUpgrade}
                                        >
                                            Submit <Spinner animation={ loading ? "border" : null} role="status" size="sm"></Spinner>
                                        </Button>
                                    </Form.Group>
                                </Row>
                            </Form>
                        </DrewsModal>
                        {
                            isManager ? (
                                <div className="logout-section">
                                    <Button 
                                        type="button"
                                        variant="primary" 
                                        className="text-white font-bold"
                                        onClick={handleLogout}
                                    >
                                        <FontAwesomeIcon icon="sign-out"/>
                                    <span className="ms-2">Logout</span>  
                                    </Button>
                                </div>
                            ) : null
                        }
                    </div>
                </Sidebar>
            </div>
        </>
    );
}


export default AppSidebar;