import { Routes, Route, Navigate } from "react-router-dom";

const renderRoute = (route, isLoggedIn, user, defaultRoute) => {
  // Redirect if authentication is required but user is not logged in
  if (!isLoggedIn && route.authRequired) {
    return <Navigate to={defaultRoute} replace />;
  }

  // Admin route check
  if (isLoggedIn && route.role === "admin" && user.user_type == "owner") {
    const AdminComponent = route.adminRoute;
    return <AdminComponent />;
  }

  // Manager route check
  if (isLoggedIn && route.role === "manager") {
    const ManagerComponent = route.component;
    return <ManagerComponent />;
  }

  // Public routes (no authentication required)
  if (!route.authRequired) {
    const PublicComponent = route.component;
    return <PublicComponent />;
  }

  // Default fallback
  return <Navigate to={defaultRoute} replace />;
};

const AppRoutes = ({ privateRoutes, isLoggedIn, user, defaultRoute }) => {
  return (
    <Routes>
      {privateRoutes.map((route) => (
        <Route
          key={route.path}
          path={route.path}
          element={renderRoute(route, isLoggedIn, user, defaultRoute)}
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;
