import InventoryServiceHelper from './service.inventory.helper';

export default class InventoryService {

    static API_URI = 'admin';

    static login(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/sign-in`, payload);
    }

    static signup(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/register`, payload);
    }

    static createAdmin(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-admin`, payload);
    }

    static addStock(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-product`, payload);
    }

    static getAllStock(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.filter_by === 'type'){
            request_params += `&transaction_type=${params.filter}`;
        }
        if(params.status !== '' && params.status !== undefined) {
            request_params += `&status=${params.status}`;
        }
        if(params.filter !== '' && params.filter !== undefined) {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== undefined) {
            request_params += `&filter_by=${params.filter_by}`;
        }
        if(params.sort_by !== '' && params.sort_by !== undefined) {
            request_params += `&sort_by=${params.sort_by}`;
        }
        if(params.download !== '' && params.download !== undefined && params.download === 'True') {
            request_params += `&download=${params.download}`;
        }
        if(params.gateway !== '' && params.gateway !== undefined) {
            request_params += `&gateway=${params.gateway}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/all-products?${request_params}`);
    }

    static getPricelist() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/pricelist/all`);
    }

    static getStockById(id) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/product/${id}`);
    }

    static updateStock(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product/${payload.id}`, payload);
    }

    static deleteStock(id) {
        return InventoryServiceHelper.handleDeleteRequest(`${this.API_URI}/product/${id}`);
    }

    static importStock(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/import-product`, payload);
    }

    static filterStock(params) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/filter-product?page=${params.page}&sku=${params.sku}&name=${params.name}`);
    }

    static searchProducts(params) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/filter-product?filter=${params}`);
    }

    static uploadProductImage(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/update-product-image/${payload.id}`, payload);
    }

    static scanBarcode(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product/${payload.id}/barcode`, payload);
    }

    static stockAnalytics() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/get-analytics`);
    }

    static restockRequest(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product-restock`, payload);
    }

    static stockRequests() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/restock`);
    }

    static manageStockRequest(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/restock-management`, payload);
    }

    static createVendor(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-vendor`, payload);
    }

    static getVendors() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/all-vendors`);
    }

    static getVendorById(vendor_id) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/vendor/${vendor_id}`);
    }

    static updateVendor(payload) {
        return InventoryServiceHelper.handlePutRequest(`${this.API_URI}/vendor/${payload.vendor_id}`, payload);
    }

    static deleteVendor(vendor_id) {
        return InventoryServiceHelper.handleDeleteRequest(`${this.API_URI}/vendor/${vendor_id}`);
    }
    
    static createOrganisation(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/create-organisation/`, payload);
    }
    
    static wallxOnaboading(payload) {
        return InventoryServiceHelper.handlePostRequest('/wallx/onboarding', payload);
    }
    
    static logOutServer() {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/sign-out`, {});
    }
    
    static productSale(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/sales`, payload);
    }
    
    static getSalesAnalytics() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/sales/analytics`);
    }
    
    static getSalesChart() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/sales/analytics/chart`);
    }
    
    static async downloadSalesChart(payload) {
        try {
            const response = await InventoryServiceHelper.handleGetRequest(
                `${this.API_URI}/sales/analytics/chart/download?from_date=${payload.from_date}&to_date=${payload.to_date}`,
                { responseType: 'blob' }
            );
    
            // Log the full response to inspect its structure
            console.log('Full response:', response);
    
            // Determine what we’re working with
            let blobData = response;
            let contentType = 'application/octet-stream'; // Default fallback
    
            // Handle different possible response formats
            if (response && typeof response === 'object') {
                if (response.data instanceof Blob) {
                    // Axios-like structure: { data: Blob, headers: ... }
                    blobData = response.data;
                    contentType = response.headers?.['content-type'] || response.data.type || contentType;
                } else if (response instanceof Blob) {
                    // Direct Blob response
                    blobData = response;
                    contentType = response.type || contentType;
                } else if (response.data && !(response.data instanceof Blob)) {
                    // Unexpected data format
                    throw new Error('Response data is not a Blob: ' + JSON.stringify(response.data));
                }
            } else {
                throw new Error('Invalid response format: ' + JSON.stringify(response));
            }
    
            console.log('Determined Content-Type:', contentType);
            console.log('Blob data:', blobData);
    
            // Check if response is JSON (indicating an error)
            if (contentType.includes('application/json')) {
                const errorText = await blobData.text();
                console.log('Error response text:', errorText);
                const errorJson = JSON.parse(errorText);
                throw new Error(errorJson.message || 'Server returned an error');
            }
    
            // Ensure we have a valid Blob
            if (!(blobData instanceof Blob)) {
                throw new Error('Response data is not a valid Blob');
            }
    
            const url = window.URL.createObjectURL(
                new Blob([blobData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            );
    
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sales_report.xlsx');
            document.body.appendChild(link);
            link.click();
    
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading sales chart:', error.message);
            alert('Failed to download sales report: ' + error.message);
            throw error;
        }
    }

    static allSales(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.filter !== '' && params.filter !== undefined) {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== undefined) {
            request_params += `&filterBy=${params.filter_by}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/sales/list?${request_params}`);
    }
    
    // Storefront ***********************************************************************************************
    static getStorefrontProducts(payload) {
        let request_payload = ``;

        if(payload.filter !== '' && payload.filter !== undefined) {
            request_payload += `&q=${payload.filter}`;
        }
        if(payload.sortBy !== '' && payload.sortBy !== undefined) {
            request_payload += `&sortBy=${payload.sortBy}`;
        }
        if(payload.orderBy !== '' && payload.orderBy !== undefined) {
            request_payload += `&orderBy=${payload.orderBy}`;
        }
        if(payload.perPage !== '' && payload.perPage !== undefined) {
            request_payload += `&perPage=${payload.perPage}`;
        }
        if(payload.page !== '' && payload.page !== undefined) {
            request_payload += `&page=${payload.page}`;
        }
        request_payload = request_payload.slice(1)
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/storefront?${request_payload}`);
    }

    static submitOrder(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/storefront/create-order`, payload);
    }

    static getOrder(order_id) {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/storefront/order/${order_id}`);
    }

    static updateOrder(payload) {
        return InventoryServiceHelper.handlePutRequest(`${this.API_URI}/storefront/order/${payload.order_id}`, payload);
    }

    static changeOrderStatus(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/storefront/manage-order`, payload);
    }

    static deleteOrder(order_id) {
        return InventoryServiceHelper.handleDeleteRequest(`${this.API_URI}/storefront/order/${order_id}`);
    }

    static productUpload(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/storefront/upload-product`, payload);
    }

    static getBrandVAT() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/storefront/get-vat-status`);
    }

    static manageVAT(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/storefront/manage-vat`, payload);
    }

    static createStorefrontProduct(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/storefront/product/create`, payload);
    }

    static importStorefrontProduct(payload) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/storefront/product/import`, payload);
    }

    static updateStorefrontProduct(payload, id) {
        return InventoryServiceHelper.handlePostRequest(`${this.API_URI}/product/${id}`, payload);
    }

    static orderAnalytics() {
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/storefront/order-analytics`);
    }

    // static getOrders(payload) {
    //     return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/storefront/orders?page=${payload.page}`);
    // }

    static getOrders(params) {
        let request_params = ``;

        if(params.from_date !== '' && params.from_date !== undefined) {
            request_params += `&from_date=${params.from_date}`; 
        }
        if(params.to_date !== '' && params.to_date !== undefined){
            request_params += `&to_date=${params.to_date}`;
        }
        if(params.filter !== '' && params.filter !== undefined) {
            request_params += `&filter=${params.filter}`;
        }
        if(params.filter_by !== '' && params.filter_by !== undefined) {
            request_params += `&filterBy=${params.filter_by}`;
        }
        if(params.page !== '' && params.page !== undefined) {
            request_params += `&page=${params.page}`;
        }
        request_params = request_params.slice(1)
        return InventoryServiceHelper.handleGetRequest(`${this.API_URI}/storefront/orders?${request_params}`);
    }

}