import React, { useState } from 'react';
import { Button, Offcanvas, Card, ListGroup, Badge } from 'react-bootstrap';
import './scss/FloatingCart.scss';
import Cart from './Cart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

const FloatingCart = ({ children }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const cart  = useSelector((state) => state.cart.items);
  const {business} = useSelector((state) => state.user)
  const itemCount = cart.length

  return (
    <>
      <Button 
        variant="primary"
        type='buttone'
        className="position-fixed rounded-circle p-3 shadow-sm"
        style={{
          right: '20px',
          bottom: '20px',
          zIndex: 1000,
          width: '60px',
          height: '60px'
        }}
        onClick={handleShow}
      >
        <div className="position-relative">
          <FontAwesomeIcon icon={'shopping-bag'} style={{"fontSize": "24px"}} />
          {itemCount > 0 && (
            <Badge 
              pill 
              bg="danger"
              className="position-absolute top-0 start-100 translate-middle"
            >
              {itemCount}
            </Badge>
          )}
        </div>
      </Button>

      <Offcanvas 
        show={show} 
        onHide={handleClose} 
        placement="end" 
        className="floating-cart"
      >
        <Offcanvas.Header closeButton className="bg-primary text-white">
          <Offcanvas.Title>
            <h5 className="mb-0">
              <FontAwesomeIcon icon={'shopping-basket'} className='me-2' />
              { business?.store_name }
            </h5>
          </Offcanvas.Title>
        </Offcanvas.Header>
        
        <Offcanvas.Body className="p-0 p-md-3x">
          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default FloatingCart;