import { Row, Col, Container, Card, Table, Spinner } from "react-bootstrap";
import Layout from "../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import formatCurrency from "../../utils/currencyFormmter";
import InventoryFilter from "../../components/InventoryFilter";
import { useNavigate } from "react-router-dom";
import { getProductPrices, getProducts, loginCustomer, restock, setupInventory } from "../../redux/slices/inventory.slice";
import InventoryConnectionButton from "../../components/InventoryConnector";

const Pricelist = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const initialState = {
        loading: false,
        show_vendor_form: false,
        showStockForm: false,
        showForm: false,
        analytics: {},
        paginationData: {next: '', prev: ''},
        payload: {
            to_currency: 'NGN', 
            currency: 'USD', 
            amount: 1,
            page: 1,
            from_date: '',
            to_date: '',
            status: '',
            download: '',
            filter_by: '',
            sort_by: '',
            filter: '',
            gateway: 'FasePay',
        },
        errors: {},
        suppliers: [],
        products: [],
        restockMode: false,
        product: {},
        pricelist: [],
    }

    const [state, setState] = useState(initialState)
    const [loading, setLoading] = useState(false)

    const { invLogin } = useSelector((state) => state.inventory)
    const { user } = useSelector((state) => state.auth)
    const { business } = useSelector((state) => state.user)

    const isLoaded = useRef(false)

    useEffect(() => {
        if(!isLoaded.current) {
            loadPrices()
            isLoaded.current = true
        }
    }, [])

    useEffect(() => {
        if(invLogin === true) {
            loadPrices()
        }
    }, [invLogin])

    const handleInput = (event) => {
        const {name, value} = event.target
         if(name === 'amount') {
            const newValue = parseFloat(value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1'));
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: newValue}}))
        }
        else {
            setState((prevState) => ({...prevState, payload: { ...prevState.payload, [name]: value}}))
        }
    }

    const handleSearch = () => {
        loadPrices()
    }

    const loadPrices = () => {
        setLoading(true)
        dispatch(getProductPrices())
        .then((response) => {
            const result = response.payload?.data?.data
            if(result) {
                setState((prevState) => ({...prevState, products:  result})) 
            }
        })
        .finally(() => setLoading(false))
    }

    return (
        <Layout>
            <Container className="mt-4 px-4 mb-4">
                <InventoryConnectionButton />
                <Col md={12} className="row align-items-center justify-content-between flex-wrapd">
                    <div className="col-md-4">
                        <h3 className="m-0">Stock Price List</h3>
                    </div>
                </Col>

                <div className="mt-4">
                    <Row className="mb-4 align-items-center justify-content-between">
                        <div className="col-md-5">
                            <h5 className="mb-2">Search Inventory</h5>
                        </div>
                        <div className="">
                            <InventoryFilter 
                                handleInput={handleInput}
                                handleSearchSubmit={handleSearch}
                            />
                        </div>
                    </Row>

                    <Card className="border-0 shadow-sm rounded-14">
                        <Card.Body className="table-responsive-lg">
                            <Table size="sm" className="table-color table-stripedd">
                                    <thead>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Item Name</th>
                                            <th>Buying Price(₦)</th>
                                            <th>Selling Price(₦)</th>
                                        </tr>
                                    </thead>
                                    <tbody className="data-container">
                                        {
                                            loading ? (
                                                <div className="in-page-loader">
                                                    <Spinner size="lg" variant="primary"/>
                                                </div>
                                            ) : null
                                        }
                                        {
                                            state.products?.length ? (
                                                state.products?.map((item, index) => (
                                                    <tr key={index}> 
                                                        <td>
                                                            <button 
                                                                type="button" 
                                                                className="btn btn-plain text-primary font-bold"
                                                                onClick={() => navigate('/inventory/preview/'+item.id)}
                                                            >
                                                                {item.sku}
                                                            </button> 
                                                        </td>
                                                        <td>{item.name}</td>
                                                        <td>{formatCurrency(item.price).with_currency} </td>
                                                        <td>{formatCurrency(item.sales_price).with_currency} </td>
                                                    </tr>
                                                ))
                                            )
                                            : 
                                            (
                                                <tr>
                                                    <td colSpan={5} className="text-center text-muted">No data found</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </div>
            </Container>
        </Layout>
    );
}

export default Pricelist;