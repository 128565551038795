import React, { useEffect } from 'react';
import {Veriff} from '@veriff/js-sdk';

const VeriffComponent = () => {
  useEffect(() => {
    // Initialize Veriff SDK
    const veriff = Veriff({
      host: 'https://api.veriff.me',
      apiKey: 'e83982b4-3a94-4885-bb72-7d7b973d6a6b', 
      parentId: 'veriff-root',
      onSession: function (err, response) {
        if (err) {
          console.error('Veriff session error:', err);
          return;
        }
        console.log('Veriff session created:', response);
        window.location.href = response.verification.url;
      },
      onEvent: function (event) {
        console.log('Veriff event:', event);
        switch (event) {
          case 'STATUS_CREATED':
            console.log('Verification session created');
            break;
          case 'STATUS_SUBMITTED':
            console.log('User submitted verification');
            break;
          case 'STATUS_DECIDED':
            console.log('Verification decision made');
            break;
          default:
            console.log('Unknown event:', event);
        }
      },
    });

    // Start the verification process
    veriff.setParams({
      person: {
        givenName: 'John', // User's first name
        lastName: 'Doe', // User's last name
      },
      vendorData: 'user-id-123', // Optional: Custom data to identify the user
    });

    veriff.mount();
  }, []);

  return (
    <div>
      <div id="veriff-root"></div>
    </div>
  );
};

export default VeriffComponent;